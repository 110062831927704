import React from 'react';
// todo udelat konstanty prepouzitelne vcetne nejakych nazvu, ne jen cislnych hodnot
export const economy = 0.5;
export const standard = 0.7;
export const premium = 1;

const options = [
    {
        desc: 'economy',
        value: 0.5,
    },
    {
        desc: 'standard',
        value: 0.7,
    },
    {
        desc: 'premium',
        value: 1,
    },
];
export default () =>
    options.map((opt) => <option value={opt.value}>{opt.desc}</option>);
