import { Backdrop } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withModal from '../decorators/withModalState';
import modals from '../../constants/modals';
import NewCycleUserSettingForms from '../../components/NewCycleUserSettingForms';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(17, 17, 17, 0.2)',
    },
}));

const modalKey = modals.NEW_CYCLE_USER_SETTING;

const NewCycleUserSetting = ({ isOpen }) => {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={isOpen || false}>
            <NewCycleUserSettingForms open={isOpen || false} modalKey={modalKey} />
        </Backdrop>
    );
};

export default NewCycleUserSetting;
