import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';

import { Class } from '@material-ui/icons';
import { getMonths, monthsInOptionMenuItem } from '../../utils/monthsWithDays';
import WSServedComponent from "../../websocketService/WSServedComponent";

/**
 * Komponenta sipek pro navigaci mezi jednotlivymi mesici
 */

class ArrowMonths extends WSServedComponent {
    constructor(props) {
        super(props);
        this.event = 'GET_GAME_MONTHS';
        this.eventResponse = 'GAME_MONTHS_HERE';
        this.eventData = {};
    }

    componentDidMount() {
        super.componentDidMount();
        let month_key = parseInt(this.props.month.split("\.")[0]) - 1;
        this.updateCustomStateProperty("month", month_key);
    }
    getCurrentMonthIndex() {
        return parseInt(this.props.month.split('.')[0], 10) - 1
    }
    createMonths = (dates) => {
        let result = []
        for (let i = 0; i < dates.length; i++) {
            result.push({ type: dates[i], value: dates[i] })
        }
        return result;
    }

    decrementMonth = () => {
        let month_key = this.getCustomStateProperty("month")
        if (month_key > 0) {
            this.updateCustomStateProperty("month", month_key - 1)
            this.props.setMonth(this.state.data[month_key - 1])
        }
    }

    incrementMonth = () => {
        let month_key = this.getCustomStateProperty("month")
        if (month_key < this.state.data.length - 1) {
            this.updateCustomStateProperty("month", month_key + 1)
            this.props.setMonth(this.state.data[month_key + 1])
        }
    }

    renderData(data) {
        return (
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className="Month stickyHeader"
          
            >
                <Button onClick={() => this.decrementMonth()}
                    disabled={this.getCustomStateProperty("month") === 0}>
                    {this.props.month !== 1 && (
                        <>
                            <ArrowBackIosIcon
                                className="arrow-table"
                                color="primary"
                                fontSize="large"
                            />
                            {getMonths()[this.getCurrentMonthIndex() - 1 < 0 ? 11 : this.getCurrentMonthIndex() - 1]}
                        </>
                    )}
                </Button>

                <TextField
                    select
                    value={this.props.month}
                    onChange={(e) => {
                        let month_key = parseInt(e.target.value.split("\.")[0]) - 1
                        this.updateCustomStateProperty("month", month_key)
                        this.props.setMonth(e.target.value);
                    }}
                >
                    {this.createMonths(data).map(({ type, value }, index) => (
                        <MenuItem key={index} value={value} style={{ zIndex: 5400 }}>{type}</MenuItem>
                    ))}
                </TextField>
                <Button onClick={() => this.incrementMonth()}
                    disabled={this.getCustomStateProperty("month") === this.state.data.length - 1}>
                    {this.props.month !== 13 && (
                        <>
                            {getMonths()[this.getCurrentMonthIndex() + 1 > 11 ? 0 : this.getCurrentMonthIndex() + 1]}

                            <ArrowForwardIosIcon
                                height="2em"
                                className="arrow-table"
                                color="primary"
                                fontSize="large"
                            />
                        </>
                    )}
                </Button>
            </Grid>
        );
    }
}

export default ArrowMonths;
