import React from 'react';

class WSForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {};
        this.state["form-values"] = [];

        if (props.hasOwnProperty("data")) {
            let formData = []
            Object.keys(props.data).map((key) => formData[key] = props.data[key]);
            this.state["form-values"] = formData
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.data !== this.props.data) {
            let formData = []
            Object.keys(this.props.data).map((key) => formData[key] = this.props.data[key]);
            this.setState({ "form-values": formData });
        }
    }

    getFormValue = (id) => {
        return this.state["form-values"][id];
    }

    onChange = (event) => {

        console.log(event);

        var componentId = event.target.name;
        var formValues = this.state["form-values"];
        if (event.target.type === "file") {
            let file = event.target.files[0]
            var reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            let self = this;
            reader.onload = function (evt) {
                formValues[componentId] = evt.target.result;
            }
        } else if (event.target.type === "checkbox") {
            formValues[componentId] = event.target.checked;
        } else {
            formValues[componentId] = event.target.value;
        }

        this.setState({ "form-values": formValues });
    }


    setFormData = (key, value) => {
        var formValues = this.state["form-values"];
        formValues[key] = value;
        this.setState({ "form-values": formValues });
    }

    autoUpdateChange = (event) => {
        var componentId = event.target.name;
        var formValues = this.state["form-values"];

        if (event.target.type === "file") {
            this.getBase64(event.target.files[0]).then(data => {
                formValues[componentId] = data;
            });
        } else if (event.target.type === "checkbox") {
            formValues[componentId] = event.target.checked;
        } else {
            formValues[componentId] = event.target.value;
        }

        this.setState({ "form-values": formValues });
        this.saveForm()
    }


    saveForm = () => {
        var message = {
            event: "FORM_SAVE",
            data: {
                formId: this.formId,
                values: { ...this.state["form-values"] }
            }
        };
        console.log(message);

        let str = JSON.stringify(message)
        window.ws.send(str);
    }

    action = (actionType) => {
        var message = {
            event: "FORM_SAVE",
            data: {
                action: actionType,
                formId: this.formId,
                values: this.state["form-values"]
            }
        };
        window.ws.send(JSON.stringify(message));
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
}

export default WSForm;
