import React from 'react';
import { Field } from 'redux-form';
import { Grid } from '@material-ui/core';
const LabelAndCheckBox = ({ label, fieldName, disable }) => {
    return (
        <Grid
            container
            sm={12}
            justify={'center'}
            alignItems={'center'}
            className={'form-check'}
        >
            <Grid container item sm={9} justify={'flex-end'} alignItems={'center'}>
                <label className={'form-label'} style={{ width: 'auto' }}>
                    {label}
                </label>
            </Grid>
            <Grid
                container
                sm={1}
                item
                justify={'flex-start'}
                alignItems={'center'}
                className={'form-padd'}
            >
                <Field
                    name={fieldName}
                    placeholder="Count"
                    component="input"
                    type="checkbox"
                    disabled={disable}
                ></Field>
            </Grid>
        </Grid>
    );
};

export default LabelAndCheckBox;
