import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



class SnackbarService extends React.Component {

    constructor(props) {
        super(props);

        this.state = { open: false, alert: { type: "", message: "" } }
        window.snackbarService = this;
    }

    sendMessage = (type, message) => {
        let state = this.state;
        state.open = true;
        state.alert.type = type;
        state.alert.message = message;
        this.setState({
            open: true,
            alert: { type, message }
        });
    }


    handleClose = () => {
        let state = this.state;
        state.open = false;
        state.alert.type = "";
        state.alert.message = "";
        this.setState({
            open: false,
            alert: {
                type: "",
                message: ""
            }
        });
    }


    render() {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={this.handleClose}>
                <Alert severity={this.state.alert.type}>{this.state.alert.message}</Alert>
            </Snackbar>
        );
    }

}
export default SnackbarService;
