import { Grid, Table, TableBody, TableRow, TableCell, Typography, Select, MenuItem, Box } from '@material-ui/core';
import React, { Component } from 'react';
import PerformanceChart from '../Charts/PerformanceChart';
import moment from 'moment';
import { getDataForPeriodSelect, prepareArrayForMonthlyChart, prepareArrayForDailyChart } from '../../utils/reportsUtils';
const data = [{
    name: 1,
    OCC: 50,
    ADR: 2500,
    RevPAR: 1200,
    TRevPAR: 1600,
    GOPAR: 2400,
}, {
    name: 2,
    OCC: 56,
    ADR: 2200,
    RevPAR: 1400,
    TRevPAR: 1600,
    GOPAR: 2600,
}, {
    name: 3,
    OCC: 68,
    ADR: 2600,
    RevPAR: 1200,
    TRevPAR: 1450,
    GOPAR: 2000,
},
{
    name: 4,
    OCC: 74,
    ADR: 2200,
    RevPAR: 1200,
    TRevPAR: 1600,
    GOPAR: 2200,
},];
export default class ExpensesAndGainsReport extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            data: data
        }
    }
    processExpensesAndGains(data) {
        let [OCC, ADR, RevPAR, TRevPAR, GOPAR] = [0, 0, 0, 0, 0];
        let chartData = this.props.reportType === 'monthly' ? prepareArrayForMonthlyChart() : prepareArrayForDailyChart();
        data.forEach(item => {
            OCC += item.OCC;
            ADR += item.ADR;
            RevPAR += item.RevPAR;
            TRevPAR += item.TRevPAR;
            GOPAR += item.GOPAR;
            chartData[item.name - 1] = {
                ...item,
                ...chartData[item.name - 1],
            };
        });
        OCC = OCC / data.length;
        ADR = ADR / data.length;
        RevPAR = RevPAR / data.length;
        TRevPAR = TRevPAR / data.length;
        GOPAR = GOPAR / data.length;
        return {
            chartData,
            OCC,
            ADR,
            RevPAR,
            TRevPAR,
            GOPAR
        };
    }
    render() {
        let reportData = this.processExpensesAndGains(data);
        return (
            <Grid container className="adminMainContent" style={{ border: "1px solid #d3d3d36b" }}>
                <Grid item xs={3}>
                    <Typography className="ReportsTableTitle" variant="h5">Výkon: </Typography>
                    <Table size="small" className="ReportsTable">
                        <TableBody>
                            <TableRow>
                                <TableCell>OCC</TableCell>
                                <TableCell>{reportData.OCC}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>ADR</TableCell>
                                <TableCell>{reportData.ADR}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>RevPAR</TableCell>
                                <TableCell>{reportData.RevPAR}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>TRevPAR</TableCell>
                                <TableCell>{reportData.TRevPAR}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>GOPAR</TableCell>
                                <TableCell>{reportData.GOPAR}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={9}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="subtitle2" style={{ marginRight: 10, fontSize: '1.1rem' }}>{this.props.reportType === 'monthly' ? "Rok: " : "Měsíc: "}</Typography>
                        <Select value={this.props.reportType === 'monthly' ? this.props.time.year : this.props.time.month}>
                            {getDataForPeriodSelect(this.props.time.startDate, this.props.time.endDate, this.props.reportType).map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <PerformanceChart data={reportData.chartData} />
                </Grid>
            </Grid >
        )
    }
}
