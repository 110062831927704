export const validateRequired = () => (values) => {
    const errors = {};
    const requiredFields = ['juniorSalary', 'seniorSalary'];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};
