import React from 'react';
import { Grid } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

/**
 * Komponenta pro zobrazeni warningu/error message
 */
const Alert = ({ text }) => (
    <Grid container xs className="Alert Alert-warning">
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            xs={1}
        >
            <ErrorOutlineIcon
                size="large"
                className="Alert-warning-icon"
            />
        </Grid>
        <Grid item xs={10} className="Alert-message">
            {text}
        </Grid>
    </Grid>
);
export default Alert;
