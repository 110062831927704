import React from 'react';
import { Paper, Box } from '@material-ui/core';

import '../../css/style.css';

import {
    rowHeadersSales,
    rowHeadersRoomTotal,
    rowHeadersRoomSales,
    rowHeadersAdvanceSales,
    rowHeadersOnlineSales,
} from '../../constants/tableHeaders';

import HotTableWrapper from './HotTableWrapper';
import ArrowMonths from '../../containers/ArrowsMonths';
import WSServedComponent from '../../websocketService/WSServedComponent';

/**
 * Komponenta tabulky s uzivatelskymi vstupy
 */

class HandsonTable extends WSServedComponent {
    constructor(props) {
        super(props);
        this.state = { ...this.state, month: props.selectedMonth };
        this.event = 'GET_INITIAL_MONTHLY_DATA';
        this.eventResponse = 'INITIAL_MONTHLY_DATA_HERE';
        this.eventData = {
            month: props.selectedMonth,
        };
        console.log(this.eventData);
        this.tableWidth = '100%';
    }

    setMonth = (month) => {
        this.setState({ month });
        this.updateWSData(
            {
                month: month,
            }
        )
    }

    updateTable = (tableId, changes) => {
        this.sendData({
            "event": "TABLE_UPDATE",
            "data": {
                "tableId": tableId,
                "month": this.state.month,
                "changes": changes
            }
        });
    }
    websocketDidUpdate() {
        if (this.state.data.month !== this.state.month) {
            this.setState({ month: this.state.data.month });
        }
    }

    renderData(data) {
        return (
            <Box position="relative">
                <ArrowMonths
                    endDate={this.props.endDate} startDate={this.props.startDate}
                    setMonth={this.setMonth}
                    month={this.state.month}
                />
                <Box style={{padding: '0 40px'}}>
                    <Paper style={{ width: this.tableWidth }} className="HotTableWrap">
                        <HotTableWrapper
                            tableId="pricesTable"
                            data={data.pricesTable}
                            updateTable={this.updateTable}
                            rowHeaders={rowHeadersSales}
                            tableWidth={this.tableWidth}
                            colorStyle="blue"
                            className="Table"
                            month={this.state.month}
                            monthChangesData={[]}
                        />
                    </Paper>
                    <Paper style={{ width: this.tableWidth }} className="HotTableWrap">
                        <HotTableWrapper
                            tableId="advanceTable"
                            data={data.advanceTable}
                            rowHeaders={rowHeadersAdvanceSales}
                            tableWidth={this.tableWidth}
                            updateTable={this.updateTable}
                            colorStyle="blue"
                            className="Table"
                            month={this.state.month}
                            monthChangesData={[]}
                        />
                    </Paper>
                    <Paper style={{ width: this.tableWidth }} className="HotTableWrap">
                        <HotTableWrapper
                            tableId="roomsTable"
                            data={data.roomsTable}
                            updateTable={this.updateTable}
                            rowHeaders={rowHeadersRoomTotal}
                            tableWidth={this.tableWidth}
                            colorStyle="green"
                            className="Table"
                            month={this.state.month}
                            readOnly
                        />
                    </Paper>
                    <Paper style={{ width: this.tableWidth }} className="HotTableWrap">
                        <HotTableWrapper
                            tableId="sellsTable"
                            data={data.sellsTable}
                            rowHeaders={rowHeadersRoomSales}
                            tableWidth={this.tableWidth}
                            updateTable={this.updateTable}
                            colorStyle="green"
                            className="Table"
                            month={this.state.month}
                            readOnly
                        />
                    </Paper>
                    <Paper style={{ width: this.tableWidth }} className="HotTableWrap">
                        <HotTableWrapper
                            tableId="onlineTable"
                            data={data.onlineTable}
                            updateTable={this.updateTable}
                            rowHeaders={rowHeadersOnlineSales}
                            tableWidth={this.tableWidth}
                            colorStyle="orange"
                            className="Table"
                            month={this.state.month}
                        />
                    </Paper>
                </Box>
            </Box>
        );
    }
}

export default HandsonTable;
