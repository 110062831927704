import React from 'react';
import { reduxForm } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import LabelAndSelectAndText from '../../LabelAndSelectAndText';

const Breakfast = () => (
    <Grid container item sm={12}>
        <form>
            <Typography
                variant={'h6'}
                color="textSecondary"
                className={'form-heading'}
            >
                Kuchař
            </Typography>
            <LabelAndSelectAndText
                label={'Senior'}
                nameSelect={'seniorSelect'}
                nameText={'seniorText'}
                namePlaceholder={'Hruba mzda'}
            />
            <LabelAndSelectAndText
                label={'Senior'}
                nameSelect={'seniorSelect'}
                nameText={'seniorText'}
                namePlaceholder={'Hruba mzda'}
            />
            <Typography
                variant={'h6'}
                color="textSecondary"
                className={'form-heading'}
            >
                Pomocná síla
            </Typography>
            <LabelAndSelectAndText
                label={'Juinor'}
                nameSelect={'seniorSelect'}
                nameText={'seniorText'}
                namePlaceholder={'Hruba mzda'}
            />
            <Typography
                variant={'h6'}
                color="textSecondary"
                className={'form-heading txt-al--cen'}
            >
                Odbyt
            </Typography>
            <Typography
                variant={'h6'}
                color="textSecondary"
                className={'form-heading'}
            >
                Obsluha
            </Typography>
            <LabelAndSelectAndText
                label={'Juinor'}
                nameSelect={'seniorSelect'}
                nameText={'seniorText'}
                namePlaceholder={'Hruba mzda'}
            />
            <LabelAndSelectAndText
                label={'Juinor'}
                nameSelect={'seniorSelect'}
                nameText={'seniorText'}
                namePlaceholder={'Hruba mzda'}
            />
        </form>
    </Grid>
);
export default reduxForm({
    form: 'MessRoomsWorkers',
})(Breakfast);
