import ReactDOM from 'react-dom';
import React from 'react';
import { CircularProgress, Modal, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Routes from './routes';
import bgImage from './assets/images/login_bg.svg';

class Root extends React.Component {
    constructor() {
        super();
        this.state = { connected: false, prevConnected: false };
    }
    componentDidMount() {
        this.connectWS();
    }
    connectWS() {
        window.ws = new WebSocket(process.env.REACT_APP_WS_SERVER_URL);
        window.ws.addEventListener('open', () => {
            this.setState({ connected: true, prevConnected: true });
            console.log('Websocket Open');
            window.ws.send(JSON.stringify({ event: "USER_RECONNECT", data: { userId:'61692608de9cf9000895b261'} }))
            if (window.localStorage.getItem('playerId')) {
                 window.ws.send(JSON.stringify({ event: "USER_RECONNECT", data: { userId: window.localStorage.getItem('playerId') } }))
             }
        });

        window.ws.addEventListener('close', () => {
            this.setState({ connected: false, prevConnected: true });
            setTimeout(() => this.connectWS(), 1000);
            console.log('Websocket Closed');
        });

        window.ws.onerror = function (event) {
            console.error("WebSocket error observed:", event);
        };
    }

    render() {
        if (this.state.connected) {
            return (
                <div>
                    <Routes />
                </div>
            );
        }
        return (
            <div>
                <Modal
                    open={!this.state.connected}
                    style={{ zIndex: 1400, backgroundImage: `url(${bgImage})`, backgroundPosition: 'center center', backgroundSize: 'cover' }}
                >
                    <Paper style={{
                        top: `${50}%`,
                        left: `${50}%`,
                        width: '400px',
                        position: 'absolute',
                        padding: '20px',
                        transform: `translate(-${50}%, -${50}%)`,
                        outline: 'none',
                        border: 'none'
                    }}
                    >
                        <Grid container style={{ textAlign: 'center' }}>
                            <Grid item xs={12}>
                                <h2>Načítání</h2>
                            </Grid>
                            <Grid container style={{ padding: '20px' }}>
                                <Grid item xs={12}>
                                    <CircularProgress />
                                </Grid>
                                <Grid item xs={12}>
                                    <p>Navazuji připojení k serveru...</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Modal>
            </div>
        );
    }
}
export default Root;



ReactDOM.render(
        <Root />
    ,
    document.getElementById('root'),
);

