import React from 'react';
import Grid from '@material-ui/core/es/Grid/Grid';
import LinearProgress from '@material-ui/core/es/LinearProgress/LinearProgress';

class WSUpdatedComponent extends React.Component {
    constructor(props) {
        super(props);

        /**
         * this.dataIDs array
         * */

        this.state = {
            data: null,
            haveData: false,
            customProperty: []
        }
        this.ws = window.ws;
        this.eventUpdate = "SERVER_UPDATE";
        this.renderData = this.renderData.bind(this);
    }
    componentDidMount() {
        this.ws.addEventListener('message', this.websocketUpdate);
    }
    componentWillUnmount() {
        this.ws.removeEventListener('message', this.websocketUpdate);
    }

    websocketUpdate = (event) => {
        const data = JSON.parse(event.data);
        if (data.event === this.eventUpdate) {
            let relevantData = this.getRelevantData(data.data)
            this.setState({
                data: relevantData,
                haveData: true,
            });
        }
    };

    getRelevantData = (data) => {
        let result = {}
        this.dataIDs.forEach(id => {
            if (data.hasOwnProperty(id)) {
                result[id] = data[id]
            }
        });
        return result
    }

    updateCustomStateProperty = (property, data) => {
        let customProperty = this.state.customProperty;
        customProperty[property] = data;
        this.setState({ customProperty: customProperty });
    }


    renderData(data) {

    }
}
export default WSUpdatedComponent;
