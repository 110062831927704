import { Box, Tab, Tabs } from '@material-ui/core';
import React, { Component } from 'react'
import PerformanceReport from '../../components/Reports/PerformanceReport';
import { TimeContext } from '../../contexts/TimeProvider';

export default class PerformanceComponent extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { ...this.state, activeTab: 'daily' };
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ activeTab: newValue })
    }

    render() {

        return (
            <Box>
            <Tabs
                value={this.state.activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleChangeTab}
            >
                <Tab value={'daily'} label="Denní výsledky" />
                <Tab value={'monthly'} label="Měsíční výsledky" />
            </Tabs>
            <PerformanceReport reportType={this.state.activeTab} time={this.context.time} />
        </Box>

        );
    }
}
PerformanceComponent.contextType = TimeContext;