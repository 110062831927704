import React from 'react';
import { HotTable } from '@handsontable/react';
import { cx } from 'ramda-extension';
import Handsontable from 'handsontable';
import { TimeContext } from '../../../contexts/TimeProvider';
import moment from 'moment';

import { daysInMonth } from "../../../utils/monthsWithDays";


function groupingRenderer(instance, td, row, col, prop, value,
    cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);

    td.innerHTML = td.innerHTML + '%'
    // td.className = "yellow-bg"
}
function eurRenderer(instance, td, row, col, prop, value,
    cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.innerHTML = td.innerHTML + '€'
    td.style.background = "#fdf7e3"
}

class HotTableWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.tableId;
        this.hotTableComponent = React.createRef();

        this.state = { data: [] }
        //this.tableData = props.data;
        //this.setTableData(props);
        this.rowHeight = 30;
    }

    componentDidUpdate(nextProps) {
       // this.tableData = nextProps.data
        //this.setTableData(nextProps);
    }
    setTableData(props) {
        console.log(props);
        if (props.data !== undefined) {
            this.tableData = props.data;
        } else {
            let rows = [];
            props.rowHeaders.forEach(() => {
                rows.push(Array.from(Array(30).keys()))
            });
            this.tableData = rows
        }
    }

    onAfterHotChange = (changes) => {
        if (changes) {
            let changesToSend = [];
            for (let i = 0; i < changes.length; i++) {
                changesToSend.push({
                    row: changes[i][0],
                    col: changes[i][1],
                    value: changes[i][3]
                })
            }
            this.props.updateTable(this.id, changesToSend)
        }
    };
    onBeforeHotChange = (changes, source) => {
        for (let i = 0; i < changes.length; i++) {
            if(/\D/g.test(changes[i][3]) || changes[i][3].length < 1) {
                changes.splice(i, 1);
            }
        }
    }
    afterSelection = (row, column) => {
        const cell = this.hotTableComponent.current.hotInstance.getCell(
            row,
            column)
        if (cell != null)
            cell.style.background = "white"
    }
    afterSelectionEnd = (row, column) => {
        const cell = this.hotTableComponent.current.hotInstance.getCell(
            row,
            column)

        if (cell != null)
            cell.removeAttribute("style")
    }

    cellsFunction = (rowCH, colCH, prop) => {
        var cellProperties = {};
        const monthChanges = this.props.monthChangesData;
        if (rowCH === 0 && this.props.tableId === 'mainTable') {
            cellProperties.renderer = eurRenderer;
        }
        if (rowCH >= 1 && rowCH <= 5 && this.props.tableId === 'mainTable') {
            cellProperties.renderer = groupingRenderer;
        }
        if ((this.tableMonth < this.currentMonth) ||
            ((this.tableMonth == this.currentMonth) && (colCH < this.currentDayInMonth))
        ) {
            cellProperties.readOnly = true;
        }
        return cellProperties;
    };


    render() {
        let {
            readOnly,
            rowHeaders,
            data,
            className,
            colorStyle,
            month
        } = this.props;

        const headerWidth = this.props.headerWidth || 210;
        this.tableMonth = moment(this.props.month, 'MM. YYYY').unix();
        this.currentMonth = moment(this.context.time.currentTime, 'DD.MM.YYYY').startOf('month').unix();
        this.currentDayInMonth = this.context.time.day;
        return (
            <HotTable
                month
                ref={this.hotTableComponent}
                id={this.id}
                afterSelection={this.afterSelection}
                afterSelectionEnd={this.afterSelectionEnd}
                afterChange={this.onAfterHotChange}
                beforeChange={this.onBeforeHotChange}
                stretchH="all"
                height={((data.length + 1) * this.rowHeight)}
                settings={{
                    data: data,
                    rowHeaders,
                    colHeaders: daysInMonth(month),
                    rowHeaderWidth: headerWidth,
                    columnHeaderHeight: this.rowHeight,
                    colWidths: 15,
                    rowHeights: this.rowHeight,
                    className: cx('HotelsHandsoneTable', [className], {
                        'Table--blue': colorStyle === 'blue',
                        'Table--green': colorStyle === 'green',
                        'Table--orange': colorStyle === 'orange',
                        'htCenter': true
                    }),
                    readOnly: readOnly || false,
                    preventOverflow: false,
                    cells: this.cellsFunction
                }}
            />
        );
    }
}
HotTableWrapper.contextType = TimeContext;
export default HotTableWrapper;
