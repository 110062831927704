import React, { useState } from 'react';
import {
    Grid, IconButton, makeStyles, Paper, Popover, Typography, Box
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import StarIcon from '@material-ui/icons/Star';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import RoomIcon from '@material-ui/icons/MeetingRoom';
import moment from 'moment';
// import DoneIcon from '@material-ui/icons/Done';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';


/**
 * Komponenta elementu v kontejneru
 */
const GridCell = ({ sm, children, icon }) => (
    <Grid
        container
        direction="row"
        xs={12}
        sm={sm}
        justify="center"
        alignItems="center"
        alignContent="center"
        className="txt-al--center"
    >
        <Grid item sm={2}>
            {icon}
        </Grid>
        <Grid item sm={sm} className="txt-al--cent">
            {children}
        </Grid>
    </Grid>
);

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(2),
        width: '250px',
    },
}));

/**
 * Komponenta poptavky ubytovani
 */
const Demand = ({ demand, declineDemand, acceptDemand }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const marginLeftIcon = {
        marginLeft: 5,
    };
    const {
        id,
        start,
        cancel,
        numGuests,
        numRooms,
        numNights,
        price,
        refound,
        satisfaction,
        atract,
        bookType,
        roomType,
        group,
        services,
    } = demand;
    const IconColor = 'action';

    const startDate = new Date(start);
    const endDate = new Date(start);
    endDate.setDate(endDate.getDate() + numNights)

    const name = 'Poptávka';

    return (
        <Paper
            style={{
                background: 'white', padding: '10px 15px', margin: '5px',
            }}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <Grid container spacing={1}>
                <Grid item sm={12} className="txt-al--cen">
                    <Box
                        className="demand-card-title"
                    >
                        {name}
                    </Box>
                </Grid>
                <Grid item sm={1}>
                    <EventIcon color={IconColor} />
                </Grid>
                <Grid item sm={9}>
                    <Typography
                        color="textSecondary"
                        style={marginLeftIcon}
                        variant="body1"
                    >
                        {moment(startDate).format('DD.MM.')}
                        {' - '}
                        {moment(endDate).format('DD.MM.')}
                    </Typography>
                </Grid>
                <Grid item sm={1}>
                    <NightsStayIcon color={IconColor} />
                </Grid>
                <Grid item sm={1}>
                    <Typography
                        color="textSecondary"
                        style={marginLeftIcon}
                        variant="body1"
                    >
                        {numNights}
                    </Typography>
                </Grid>
                <Grid item sm={1}>
                    <EuroSymbolIcon color={IconColor} />
                </Grid>
                <Grid item sm={5}>
                    <Typography
                        color="textSecondary"
                        style={marginLeftIcon}
                        variant="body1"
                    >
                        {price}
                    </Typography>
                </Grid>
                <Grid item sm={1}>
                    <StarIcon color={IconColor} />
                </Grid>
                <Grid item sm={1}>
                    <Typography
                        color="textSecondary"
                        style={marginLeftIcon}
                        variant="body1"
                    >
                        {roomType >= 0.7 ? 'P' : 'S'}
                    </Typography>
                </Grid>
                <Grid item sm={1}>
                    <PersonIcon color={IconColor} />
                </Grid>
                <Grid item sm={1}>
                    <Typography
                        color="textSecondary"
                        style={marginLeftIcon}
                        variant="body1"
                    >
                        {numGuests}
                    </Typography>
                </Grid>
                <Grid item sm={1}>
                    <RoomIcon color={IconColor} />
                </Grid>
                <Grid item sm={1}>
                    <Typography
                        color="textSecondary"
                        style={marginLeftIcon}
                        variant="body1"
                    >
                        {numRooms}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6} className="txt-al--cen">
                    <IconButton
                        style={{ padding: '6px' }}
                        onClick={() => acceptDemand(id)}
                    >
                        <CheckCircleOutlineRoundedIcon
                            style={{ color: '#009aff' }}
                            fontSize="large"
                        />
                    </IconButton>
                </Grid>
                <Grid item xs={6} className="txt-al--cen">
                    <IconButton
                        onClick={() => declineDemand(id)}
                        style={{ padding: '6px' }}>
                        <CancelOutlinedIcon
                            fontSize="large"
                            color="error"
                        />
                    </IconButton>
                </Grid>
            </Grid>
            <Popover
                xs={2}
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid item>
                    <Typography color="textSecondary" variant="body1">
                        <strong>Jméno:</strong>
                        {' '}
                        {name}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                        <strong>Termín:</strong>
                        {' '}
                        {moment(startDate).format('DD.MM.')}
                        {' - '}
                        {moment(endDate).format('DD.MM.')}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                        <strong>Počet nocí:</strong>
                        {' '}
                        {numNights}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                        <strong>Počet osob:</strong>
                        {' '}
                        {numGuests}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                        <strong>Počet pokojů:</strong>
                        {' '}
                        {numRooms}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                        <strong>Typ pokoje:</strong>
                        {' '}
                        {roomType >= 0.7 ? 'premium' : 'standard'}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                        <strong>Skupina:</strong>
                        {' '}
                        {group ? 'ano' : 'ne'}
                    </Typography>
                </Grid>
            </Popover>
        </Paper >
    );
};

export default Demand;
