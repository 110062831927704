import React, { Component } from 'react'
import moment from 'moment';
export const TimeContext = React.createContext()
export default class TimeProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '2021-01-01',
            endDate: '2022-01-01',
            currentTime: '01.01.2021',
            secForRound: 20,
            scheduledRoundEndLocalMillis: 0,
            round: 1,
            totalRoundsCount: 365,
            day: 1,
            month: '01. 2021',
            year: 2021,
            status: 'RUNNING'

        }
        this.setTime = this.setTime.bind(this);
    }
    setTime(time) {
        if (this.state.currentTime !== time.currentTime) {
            let currentTime = moment(time.currentTime, 'DD.MM.YYYY');
            time.day = currentTime.toDate().getDate();
            if (currentTime.format('MM. YYYY') !== this.state.month) {
                time.month = currentTime.format('MM. YYYY');
            }
            if (currentTime.toDate().getFullYear() !== this.state.year) {
                time.year = currentTime.toDate().getFullYear();
            }
        }
        if(time.status && time.status === this.state.status) {
            delete time.status;
        }
        if ((time.startDate && time.endDate) &&
            (time.startDate !== this.state.startDate || time.endDate !== this.state.endDate)) {
            const startDate = moment(time.startDate, 'YYYY-MM-DD');
            const endDate = moment(time.endDate, 'YYYY-MM-DD');
            console.log('date set', startDate, endDate);
            console.log(time);
            time.totalRoundsCount = endDate.diff(startDate, 'days');
        }
        this.setState(time);
    }
    render() {
        return (
            <TimeContext.Provider value={{ time: this.state, setTime: this.setTime }}>
                {this.props.children}
            </TimeContext.Provider>
        )
    }
}
