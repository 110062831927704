import React from 'react'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import HotelTableRow from "./HotelTableRow";

class GameHotelsTable extends React.Component {


    constructor(props, context) {
        super(props, context);

        this.state = { hotels: [] }
    }
    
    componentDidMount() {
        window.ws.addEventListener('message', this.websocketUpdate);
        if (this.props.gameId) {
            var message = {
                event: "HOTEL_LIST",
                data: { gameId: this.props.gameId }
            };
            window.ws.send(JSON.stringify(message));
        }
    }

    componentWillUnmount() {
        window.ws.removeEventListener('message', this.websocketUpdate);
    }

    websocketUpdate = (event) => {
        const data = JSON.parse(event.data);
        if (data.event === "HOTEL_LIST") {
            this.setState({ hotels: data.data });
        }
    }

    addHotel = () => {
        this.setState({ hotels: [...this.state.hotels, { id: "" }] });
    }


    render() {

        let gameId = this.props.gameId;

        return (
            <Box>
                <Typography variant="h4" className="adminPageSubtitle">
                    Hotely ve hře
                </Typography>

                <TableContainer style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Název Hotelu</TableCell>
                                <TableCell>Hráč</TableCell>
                                <TableCell>Kapacita</TableCell>
                                <TableCell>Míra delux</TableCell>
                                <TableCell>Uroven značky</TableCell>
                                <TableCell align="right">
                                    <Button variant="contained" color="primary" onClick={this.addHotel}>
                                        <AddIcon fontSize="small" /> Přidat hotel
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.hotels.map((row, index) => (
                                <HotelTableRow key={row.id} gameId={gameId} data={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }

}
export default GameHotelsTable;
