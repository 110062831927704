import React from 'react';
import { Paper } from '@material-ui/core';
import AdminGames from "./AdminGames";

/**
 * Komponenta/obrazovka s prehledem her
 */

class ListOfGames extends React.Component {

    render() {
        return (
            <div>
                <h1 className="adminPageTitle">Přehled her</h1>
                <Paper className="adminMainContent">
                    <AdminGames />
                </Paper>
            </div>
        );
    }

}
export default ListOfGames;
