import React from 'react';
import { Paper, Tab, Tabs, AppBar, Typography } from '@material-ui/core';
import EditGameCommonComponent from "./EditGameCommonComponent";
import CreateGameEconomicsComponent from "./CreateGameEconomicsComponent";
import CreateGameBalancingComponent from "./CreateGameBalancingComponent";
import { withRouter } from "react-router";
import FileUpload from '../../components/Form/FileUpload';
//import WSServedComponent from "../../websocketService/WSServedComponent";

class CreateGame extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.state = { ...this.state, activeTab: 1, id: this.props.match.params.id };

    }

    handleChangeTab = (event, newValue) => {
        this.setState({ activeTab: newValue })
    }

    fileUploaded = (data) => {
        this.setState({
            id: -1,
            gameSettingId: data.market_id,
            gameStart: moment(data.config_start_game, 'YYYY-MM-DD').toDate(),
            gameEnd: moment(data.config_start_game, 'YYYY-MM-DD').toDate(),
            capacity: data.capacity,
            currency: data.currency
        })
    }


    render() {


        var content = <div>none</div>
        switch (this.state.activeTab) {
            case 1:
                content = <EditGameCommonComponent />;
                break;
            case 2:
                content = <CreateGameEconomicsComponent />;
                break;
            case 3:
                content = <CreateGameBalancingComponent />;
                break;
        }



        return (
            <div>
                <h1 className="adminPageTitle">{this.state.id ? 'Nastavení hry' : 'Nová hra'}</h1>
                <Paper className="adminPaperBox">
                    {this.state.id &&
                        <div>
                            <AppBar position="static" elevation={0}>
                                <Tabs
                                    value={this.state.activeTab}
                                    onChange={this.handleChangeTab}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#FFFFFF"
                                        }
                                    }}
                                >
                                    <Tab value={1} label="Obecné" />
                                    <Tab value={2} label="Ekonomika" />
                                    <Tab value={3} label="Vyvážení modelu" />
                                </Tabs>
                            </AppBar>
                            <div className="adminMainContent">
                                {React.cloneElement(content, {
                                    id: this.state.id,
                                    gameSettingId: this.state.gameSettingId,
                                    gameStart: this.state.gameStart,
                                    gameEnd: this.state.gameEnd,
                                    capacity: this.state.capacity,
                                    currency: this.state.currency
                                })}
                            </div>
                        </div>
                    }
                    {!this.state.id &&
                        <div className="adminMainContent">
                            <Typography variant="h4">Konfigurace hry</Typography>
                            <p>Nahrajte prosím konfigurační soubor hry.</p>
                            <FileUpload fileUploaded={this.fileUploaded} name={"gameData"} fileId={"configFile"} />
                        </div>
                    }
                </Paper>

            </div>
        );
    }
}


export default withRouter(CreateGame);
