import { Box, Tab, Tabs } from '@material-ui/core';
import React, { Component } from 'react'
import MarketRoomCosts from './MarketRoomCosts';
import MarketServices from './MarketServices';

export default class MarketRivals extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedTab: 1 }
    }
    changeTab = (event, newValue) => {
        this.setState({ selectedTab: newValue });
    }
    render() {
        let content = ""
        switch (this.state.selectedTab) {
            case 1:
                content = <MarketRoomCosts />
                break;
            case 2:
                content = <MarketServices />
                break;
        }
        return (
            <Box className="height100">
                <Box className="stickyHeader" alignItems="flex-end">
                    <Tabs
                        value={this.state.selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.changeTab}
                    >
                        <Tab value={1} label="Ceny pokojů" />
                        <Tab value={2} label="Nabídka služeb" />
                    </Tabs>
                </Box>
                <Box className="adminMainContent" style={{ minHeight: 'calc(100% - 100px)', border: "1px solid #d3d3d36b"}}>
                    {content}
                </Box>
            </Box>
        )
    }
}
