import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    Stepper,
    Step,
    StepLabel,
} from '@material-ui/core';
import React, { useState } from 'react';


import FaBWorkers from '../../containers/Forms/App/Forms/FaBWorkers';
import CateringServicesProvided from '../../containers/Forms/App/Forms/CateringServicesProvided';
import QualityEquipmentHotel from '../../containers/Forms/App/Forms/QualityEquipmentHotel';
import ReceptionWorkers from '../../containers/Forms/App/Forms/ReceptionWorkers';
import SpendingOnPromotion from '../../containers/Forms/App/Forms/SpendingOnPromotion';
import QualityOfRawRooms from '../../containers/Forms/App/Forms/QualityOfRawRooms';
import FoodMaterial from '../../containers/Forms/App/Forms/FoodMaterial';

const steps = [
    'HR služby',
    'HR služby',
    'FaB',
    'FaB & HR',
    'Kvalita vyb. h.',
    'KS Ubyt',
    'KS FaB',
    'Kvalita Fab',
];

/**
 * Komponenta vyskakovaciho okna (modalu) pro nastaveni noveho cyklu
 */
const NewCycleUserSettingForms = ({
    open,
    modalKey,
    savePromotional,
    submit,
    hideModal,
    saveFacility,
    foodMaterial,
}) => {
    const [activeStep, setActiveStep] = useState(0);

    /**
       * Funkce pro posun vpred/na dalsi stranu v modalu
       */
    const handleNext = async () => {
        console.log(activeStep);
        if (activeStep === 4) {
            submit('Facility');
        }
        if (activeStep === 5) {
            submit('Promotions');
        }
        if (activeStep === 7) {
            submit('FoodMaterial');
        }

        setTimeout(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }, 200);
    };

    /**
       * Funkce pro posun zpet/na predchozi stranu v modalu
       */
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    console.log('activeStep', activeStep);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            disableBackdropClick
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    style={{ cursor: 'pointer' }}
                >
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel onClick={() => setActiveStep(index)}>
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </DialogTitle>
            <DialogContent className="FlexCenter">
                {activeStep === 0 && (
                    <div style={{ width: '60%' }}>

                    </div>
                )}
                {activeStep === 1 && (
                    <div style={{ width: '60%' }}>
                        <ReceptionWorkers />
                    </div>
                )}
                {activeStep === 2 && (
                    <div style={{ width: '90%' }}>
                        <FaBWorkers />
                    </div>
                )}
                {activeStep === 3 && (
                    <div style={{ width: '60%' }}>
                        <CateringServicesProvided />
                    </div>
                )}
                {activeStep === 4 && (
                    <div style={{ width: '80%' }}>
                        <QualityEquipmentHotel
                            form="Facility"
                            submitForm={saveFacility}
                        />
                    </div>
                )}
                {activeStep === 5 && (
                    <div style={{ width: '60%' }}>
                        <SpendingOnPromotion
                            form="Promotions"
                            submitForm={savePromotional}
                        />
                    </div>
                )}

                {activeStep === 6 && (
                    <div style={{ width: '60%' }}>
                        <QualityOfRawRooms />
                    </div>
                )}
                {activeStep === 7 && (
                    <div style={{ width: '60%' }}>
                        <FoodMaterial
                            form="FoodMaterial"
                            submitForm={foodMaterial}
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    Předochozí
                </Button>
                {activeStep === steps.length - 1 ? (
                    <Button
                        color="primary"
                        onClick={() => {
                            submit('FoodMaterial');
                            hideModal(modalKey);
                        }}
                    >
                        Zavřít
                    </Button>
                ) : (
                    <Button color="primary" onClick={handleNext}>
                        Další
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default NewCycleUserSettingForms;
