import { Tab, Tabs, Box } from '@material-ui/core';
import React, { Component } from 'react'

import ExpensesAndGainsReport from '../../components/Reports/ExpensesAndGainsReport';
import { TimeContext } from '../../contexts/TimeProvider';

export default class ExpensesAndGainsComponent extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ...this.state,
            activeTab: 'monthly'
        };
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ activeTab: newValue })
    }

    render() {
        return (
            <Box>
                <Tabs
                    value={this.state.activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChangeTab}
                >
                    <Tab value={'daily'} label="Denní výsledky" />
                    <Tab value={'monthly'} label="Měsíční výsledky" />
                </Tabs>
                <ExpensesAndGainsReport reportType={this.state.activeTab} time={this.context.time} />
            </Box>

        );
    }
}
ExpensesAndGainsComponent.contextType = TimeContext;