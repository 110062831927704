import { getMonths } from './monthsWithDays';

export const getDataForPeriodSelect = (startDate, endDate, reportType) => {
    let timeValues = [];
    if (startDate && endDate) {
        let dateStart = moment(startDate);
        let dateEnd = moment(endDate);

        while (dateEnd > dateStart || dateStart.format(reportType === 'monthly' ? 'Y' : 'M') === dateEnd.format(reportType === 'monthly' ? 'Y' : 'M')) {
            timeValues.push(dateStart.format(reportType === 'monthly' ? 'YYYY' : 'MM. YYYY'));
            dateStart.add(1, reportType === 'monthly' ? 'year' : 'month');
        }
    }
    return timeValues;
}
export const prepareArrayForMonthlyChart = () => {
    let months = [];
    for (let i = 0; i < 12; i++) {
        months.push({ name: getMonths()[i] });
    }
    return months;
}
export const prepareArrayForDailyChart = () => {
    let days = [];
    for (let i = 0; i < 31; i++) {
        days.push({ name: (i + 1) + '.' });
    }
    return days;
}