import React from 'react'
import { MenuItem, Select } from "@material-ui/core";

class SelectUser extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.state = { users: [] }
    }
    
    componentDidMount() {
        window.ws.addEventListener('message', this.websocketUpdate);
        var message = {
            event: "USER_LIST"
        };
        window.ws.send(JSON.stringify(message));
    }
    componentWillUnmount() {
        window.ws.removeEventListener('message', this.websocketUpdate);
    }
    websocketUpdate = (event) => {
        const data = JSON.parse(event.data);
        if (data.event === "USER_LIST") {
            this.setState({ users: data.data });

        }
    }

    render() {
        return (
            <Select
                labelId="demo-simple-select-label"
                onChange={this.props.onChange}
                value={this.props.value || ''}
                name={this.props.name}
            >
                {this.state.users.map(user => <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>)}
            </Select>
        );
    }
}
export default SelectUser;
