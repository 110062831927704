import React from 'react';
import { Grid, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

class FABResourceQuality extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
    }
    componentDidUpdate() {
        this.data = this.props.data;
    }
    options0to100 = () => {
        let rows = [];
        for (let i = 0; i < 100; i++) {
            rows.push(i);
        }
        return rows;
    }
    updateInput = (event) => {
        this.data[event.target.name] = event.target.value;
        this.props.updateQuality(this.data);
    }
    render() {
        return (
            <Grid container>
                <Grid item sm={12}>
                    <label className="form-label" style={{ fontWeight: "bold" }}>{this.props.label}</label>
                </Grid>
                <Grid item sm={12}>
                    <Grid container>
                        <Grid item sm={6}>
                            <label className="form-label">Úroveň kvality</label>
                        </Grid>
                        <Grid item sm={6}>
                            <Select
                                labelId="demo-simple-select-label"
                                name="quality"
                                value={this.props.data.quality}
                                onChange={this.updateInput}
                            >
                                <MenuItem value={0.5}>economy</MenuItem>
                                <MenuItem value={0.7}>standard</MenuItem>
                                <MenuItem value={1}>premium</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <Grid container>
                        <Grid item sm={6}>
                            <label className="form-label">Náklady snídaně</label>
                        </Grid>
                        <Grid item sm={6}>
                            <TextField
                                id="standard-basic"
                                name="brCosts"
                                value={this.props.data.brCosts}
                                disabled={true}
                                onChange={this.updateInput}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6}>
                            <label className="form-label">Náklady skupinových večeří</label>
                        </Grid>
                        <Grid item sm={6}>
                            <TextField
                                id="standard-basic"
                                name="groupDinnerCosts"
                                value={this.props.data.groupDinnerCosts}
                                disabled={true}
                                onChange={this.updateInput}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6}>
                            <label className="form-label">Náklady á la carte</label>
                        </Grid>
                        <Grid item sm={6}>
                            <TextField
                                id="standard-basic"
                                name="alacarteCosts"
                                value={this.props.data.alacarteCosts}
                                disabled={true}
                                onChange={this.updateInput}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6}>
                            <label className="form-label">Náklady lobby bar</label>
                        </Grid>
                        <Grid item sm={6}>
                            <TextField
                                id="standard-basic"
                                name="lobbyBarCosts"
                                value={this.props.data.lobbyBarCosts}
                                disabled={true}
                                onChange={this.updateInput}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
export default FABResourceQuality;
