import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';

import Demand from '../../components/Demands/Demand';
import WSServedComponent from '../../websocketService/WSServedComponent';
import TimeRound from '../TimeRound';

/**
 * Komponenta bočního panelu s jednotlivými poptávkami
 */

class DemandsSideBar extends WSServedComponent {
    constructor(props) {
        super(props);
        this.event = 'GET_DEMANDS';
        this.eventResponse = 'DEMANDS_HERE';
        console.log(props)
        this.demands = [];
        this.eventData = {}; // TODO
    }
    componentDidMount(){
        super.componentDidMount();
        window.ws.addEventListener('message', this.newDemand);
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        window.ws.removeEventListener('message', this.newDemand);
    }

    newDemand = (event) => {
        const data = JSON.parse(event.data);
        console.log(event.data)
        if (data.event === "NEW_DEMAND_OFFER") {
            this.setState({ data: [...this.state.data, data.data] });
        }
    }

    removeDemandFromList = (id) => {
        this.setState({ data: this.state.data.filter(demand => demand.id !== id) });
    }

    declineDemand = (demandId) => {
        this.sendData({
            event: "DECLINE_DEMAND",
            data:
            {
                demandId: demandId,
                hotelId: this.props.hotelId
            }
        })
        this.removeDemandFromList(demandId)
    }

    acceptDemand = (demandId) => {
        this.sendData({
            event: "ACCEPT_DEMAND",
            data:
            {
                demandId: demandId,
                hotelId: this.props.hotelId
            }
        })
        this.removeDemandFromList(demandId)
    }

    renderData(data) {
        // this.demands.push(data);
        // this.updateCustomStateProperty('demands', this.demands);

        return (
            <Paper style={{ height: '100%' }}>
                <Grid container>
                    <h2 className="demand-title">Aktuální poptávka</h2>
                </Grid>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    className="requests-bar"
                >

                    {data && data.map((demand, index) => <Demand key={index} demand={demand} acceptDemand={this.acceptDemand} declineDemand={this.declineDemand} />)}
                    {data.length < 1 && (
                        <Typography className="txt-al--cen" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            Aktuálně není žádná poptávka k dispozici.
                        </Typography>
                    )}
                </Grid>
                <TimeRound />
            </Paper>
        );
    }
}
export default DemandsSideBar;
