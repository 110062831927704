import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import WSServedComponent from '../../websocketService/WSServedComponent';
import 'react-circular-progressbar/dist/styles.css';
import PausedGameBackDrop from '../../components/PausedGame/PausedGameBackDrop';
import GameEndedBackDrop from '../../components/GameEndedBackDrop';
import { TimeContext } from '../../contexts/TimeProvider';
import { Box, Grid } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
class TimeRound extends WSServedComponent {
    constructor(props) {
        super(props);
        this.event = 'GET_SERVER_UPDATE';
        this.eventResponse = 'SERVER_UPDATE';

        this.state = {
            data: {
                gameState: ''
            },
            scheduledRoundEndLocalMillis: 0,
            indicateSecondsToEnd: 0,
        };
        this.fastTicker = this.fastTicker.bind(this);
        this.timer = null;
    }
    componentDidMount() {
        super.componentDidMount();
        console.log("TimeRound.componentDidMount");
        this.timer = setInterval(() => {
            this.fastTicker()
        }, 200);
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    websocketDidUpdate(event) {
        const systemMillis = Date.now();
        const scheduledRoundEndMillisOffset = this.state.data.timer.scheduledRoundEndMillis - this.state.data.timer.systemMillis;

        this.setState({
            scheduledRoundEndLocalMillis: systemMillis + scheduledRoundEndMillisOffset,
        });
        this.context.setTime({
            scheduledRoundEndLocalMillis: this.state.scheduledRoundEndLocalMillis,
            round: this.state.data.timer.round,
            status: this.state.data.gameState,
            secForRound: this.state.data.timer.roundLength,
            currentTime: this.state.data.timer.currentDate
        });

    }

    fastTicker() {
        if (this.context.time.status === 'RUNNING') {
            const systemMillis = Date.now();
            let secondsLeft = Math.ceil(Math.max(0, (this.state.scheduledRoundEndLocalMillis - systemMillis) / 1000));
            //Update poctu sekund do konce kola potrebuje znat jen casovac, pokud by byl v contextu zbytecne by se prekreslovali i jine komponety ktere context pouzivaji i kdyz skundy znat nepotrebuji.
            this.setState({
                indicateSecondsToEnd: secondsLeft
            })
        } else if (this.context.time.status === 'ADMIN_PAUSED') {
            // indicate remaining seconds using the time elapased on server (eg. on page-load into the paused game, there is no local timer state yet)
            const serverRoundElapsedMillis = this.state.data.timer.pausedRemainingMillis;
            let secondsLeft = Math.ceil(serverRoundElapsedMillis / 1000);
            this.setState({
                indicateSecondsToEnd: secondsLeft
            })
        }
    };

  

    renderData() {

        let content = <div>GAME IS PAUSED</div>;
        const progressStyles = {
            path: {
                stroke: `#3f51b5`,
                strokeLinecap: 'butt',
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transformOrigin: 'center center',
            },
            trail: {
                stroke: '#d9d9d9',
                strokeLinecap: 'butt',
                transformOrigin: 'center center',
            },
            background: {
                fill: '#d9d9da',
            },
        };
        if (this.context.time.status === 'RUNNING' || this.context.time.status === 'ADMIN_PAUSED') {

            content = <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                    maxHeight: '14vh',
                    height: '100%',
                }}
            >
                <Grid item xs={4}>

                    <Box className="RoundTime">
                        <Box className="text">Kolo</Box>
                        <CircularProgressbar
                            value={this.context.time.totalRoundsCount - this.context.time.round}
                            maxValue={this.context.time.totalRoundsCount}
                            minValue={0}
                            strokeWidth={15}
                            styles={progressStyles}
                        />
                        <Box className="value">{this.context.time.round}</Box>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className="RoundTime">
                        <Box className="text">Zbývá</Box>
                        <CircularProgressbar
                            value={this.state.indicateSecondsToEnd}
                            maxValue={this.context.time.secForRound}
                            minValue={0}
                            strokeWidth={15}
                            styles={progressStyles}
                        />
                        <Box className="value">{this.state.indicateSecondsToEnd}</Box>
                    </Box>
                </Grid>
            </Grid>;
        } else if (this.context.time.status === 'STOPPED') {
            content = <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                    maxHeight: '14vh',
                    height: '100%',
                }}
            >
                Hra je zastavena
            </Grid>;
        }

        let paused = '';
        if (this.context.time.status === 'ADMIN_PAUSED') {
            paused = <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                    maxHeight: '14vh',
                    height: '100%',
                }}
            >
                Hra je pozastavena administrátorem
            </Grid>;
        }

        if (this.context.time.status === 'END_MONTH_PAUSED') {
            paused = <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                    maxHeight: '14vh',
                    height: '100%',
                }}
            >
                Hra je pozastavena kvůli konci měsíce
            </Grid>;
        }

        return (
            <Grid
                container
                justify="center"
                alignItems="center"
                className="time-round-info"
            >
                <PausedGameBackDrop open={this.context.time.status === 'STOPPED'} />
                <GameEndedBackDrop open={this.context.time.status === 'ENDED'} />
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    {paused}
                    <Box className="CurrentGameDate"><EventIcon color="primary" />{this.context.time.currentTime}</Box>
                </Grid>
                {content}

            </Grid>
        );
    }

}
TimeRound.contextType = TimeContext;
export default TimeRound;
