import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { validateRequired } from '../../../../Modals/utils/validations';
import { options0to100 } from '../../../../Modals/utils/reduxForm';
import PersonSalaryInput from '../../../../../components/Form/PersonSalaryInput';
import WorkersSettingsComponent from '../../../../../components/WorkerSettingsComponent';
import WSServedComponent from "../../../../../websocketService/WSServedComponent";

class WorkersSettings extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Grid container item sm={12}>
                <Grid item sm={12}>
                    <h3 style={{ width: '100%', textAlign: 'center' }}>Ubytovací úsek</h3>
                </Grid>
                <Grid item sm={6}>
                    <WorkersSettingsComponent id="receptionists" label="Pracovníci recepce" data={this.props.workers.receptionists} updateWorker={this.props.updateWorker} />
                </Grid>
                <Grid item sm={6}>
                    <WorkersSettingsComponent id="roomService" label="Pracovníci úklidu pokojů" data={this.props.workers.roomService} updateWorker={this.props.updateWorker} />
                </Grid>
                <Grid item sm={12}>
                    <h3 style={{ width: '100%', textAlign: 'center' }}>Stravovací úsek</h3>
                </Grid>
                <Grid item sm={6}>
                    <Grid item sm={12}>
                        <h3 style={{ width: '100%', textAlign: 'center' }}>Snídaně</h3>
                    </Grid>
                    <Grid item sm={12}>
                        <h4 style={{ width: '100%', textAlign: 'center' }}>Kuchyně</h4>
                    </Grid>
                    <Grid item sm={12}>
                        <WorkersSettingsComponent id="chefBreak" label="Kuchař" data={this.props.workers.chefBreak} updateWorker={this.props.updateWorker} />
                    </Grid>
                    <Grid item sm={12}>
                        <WorkersSettingsComponent id="auxKitBreak" label="Pomocná pracovní síla" data={this.props.workers.auxKitBreak} updateWorker={this.props.updateWorker} />
                    </Grid>
                    <Grid item sm={12}>
                        <h4 style={{ width: '100%', textAlign: 'center' }}>Odbyt</h4>
                    </Grid>
                    <Grid item sm={12}>
                        <WorkersSettingsComponent id="waiterBreak" label="Obsluha" data={this.props.workers.waiterBreak} updateWorker={this.props.updateWorker} />
                    </Grid>
                </Grid>
                <Grid item sm={6}>
                    <Grid item sm={12}>
                        <h3 style={{ width: '100%', textAlign: 'center' }}>Skupinové večeře</h3>
                    </Grid>
                    <Grid item sm={12}>
                        <h4 style={{ width: '100%', textAlign: 'center' }}>Kuchyně</h4>
                    </Grid>
                    <Grid item sm={12}>
                        <WorkersSettingsComponent id="chefDin" label="Kuchař" data={this.props.workers.chefDin} updateWorker={this.props.updateWorker} />
                    </Grid>
                    <Grid item sm={12}>
                        <WorkersSettingsComponent id="auxKitDin" label="Pomocná pracovní síla" data={this.props.workers.auxKitDin} updateWorker={this.props.updateWorker} />
                    </Grid>
                    <Grid item sm={12}>
                        <h4 style={{ width: '100%', textAlign: 'center' }}>Odbyt</h4>
                    </Grid>
                    <Grid item sm={12}>
                        <WorkersSettingsComponent id="waiterDin" label="Obsluha" data={this.props.workers.waiterDin} updateWorker={this.props.updateWorker}/>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default WorkersSettings;
