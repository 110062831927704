import React from 'react';
import { Form, reduxForm } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import LabelAndSelect from '../LabelAndSelect';
import LabelAndText from '../LabelAndText';
import levelToOptions from '../../../../../constants/selectConstants/level';

const FoodMaterial = ({ disable, handleSubmit, submitForm }) => (
    <Grid container item sm={12} className={'form-group'}>
        <Form className={'form-example'} onSubmit={handleSubmit(submitForm)}>
            <Typography
                variant={'h5'}
                color="textSecondary"
                className={'txt-al--cen form-heading'}
            >
                Úroveň kvality
            </Typography>
            <LabelAndSelect
                label={'Úroveň vybavení hotelu'}
                labelGrid={7}
                selectGrid={3}
                disable={disable}
                fieldName={'level'}
                options={levelToOptions}
            />

            <LabelAndText
                label={'Úroveň vybavení hotelu'}
                labelGrid={7}
                textGrid={3}
                disable={true}
                fieldName={'value'}
            />
        </Form>
    </Grid>
);
export default reduxForm({})(FoodMaterial);
