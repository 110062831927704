import { Backdrop, Dialog, DialogContent, Typography } from "@material-ui/core";
import React from "react";

const GameEndedBackDrop = ({ open }) => (
    <Backdrop open={open}>
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Typography className="dots txt-al--cen" variant="h3">
                    Hra skončila
                </Typography>
            </DialogContent>
        </Dialog>
    </Backdrop>
);

export default GameEndedBackDrop;
