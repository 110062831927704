import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Breakfast from './Breakfast';
import GroupDinners from './GroupDinners';

const FaBWorkers = () => {
    return (
        <Grid container item sm={12} className={'form-group'}>
            <div className={'form-example'}>
                <Typography
                    variant={'h5'}
                    color="textSecondary"
                    className={'txt-al--cen form-heading'}
                >
                    Pracovníci FaB úseku
                </Typography>
                <Grid container direction="row" sm={12} item>
                    <Grid
                        container
                        sm={6}
                        className={'form-group'}
                        style={{ paddingRight: '3px' }}
                    >
                        <div className={'form-example'}>
                            <Typography
                                variant={'h5'}
                                color="textSecondary"
                                className={'txt-al--cen form-heading'}
                            >
                                Snídaně
                            </Typography>
                            <Typography
                                variant={'h6'}
                                color="textSecondary"
                                className={'txt-al--cen form-heading'}
                            >
                                Kuchyně
                            </Typography>
                            <Breakfast />
                        </div>
                    </Grid>
                    <Grid
                        container
                        sm={6}
                        className={'form-group'}
                        style={{ paddingLeft: '3px' }}
                    >
                        <div className={'form-example'}>
                            <Typography
                                variant={'h5'}
                                color="textSecondary"
                                className={'txt-al--cen form-heading'}
                            >
                                Skupinové večeře
                            </Typography>
                            <Typography
                                variant={'h6'}
                                color="textSecondary"
                                className={'txt-al--cen form-heading'}
                            >
                                Kuchyně
                            </Typography>
                            <GroupDinners />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    );
};

export default FaBWorkers;
