import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthProvider';

class AdminRoute extends React.Component {
    render() {
        return (
            <Route exact path={this.props.path}>
                {
                    this.context.isAdmin ? (
                        this.props.children
                    ) : (
                        <Redirect to="/games" />
                    )
                }
            </Route>
        );
    }
}
AdminRoute.contextType = AuthContext;
export default AdminRoute;
