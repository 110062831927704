import React from 'react';
import { Box, Tab, Tabs, Button } from '@material-ui/core';
import ServicesSettings from "../Forms/ServicesSettings";
import WorkersSettings from "../Forms/WorkersSettings";
import QualitySettings from "../../../../components/QualitySettingsComponent";
import { TimeContext } from '../../../../contexts/TimeProvider';
import WSServedComponent from '../../../../websocketService/WSServedComponent';

class MonthlyEntersForm extends WSServedComponent {
    constructor(props) {
        super(props);
        console.log(props.currentMonth);
        this.event = 'GET_MONTH_SETTINGS';
        this.eventResponse = 'MONTH_SETTINGS_HERE';
        this.eventData = { month: props.currentMonth };
    }
    componentDidMount() {
        super.componentDidMount();
        this.updateCustomStateProperty('selectedTab', 1);
        this.updateCustomStateProperty('unsavedChanges', false)

    }
    changeTab = (event, newValue) => {
        this.updateCustomStateProperty('selectedTab', newValue)
    }
    saveMonthlyEnters = () => {
        var message = {
            event: "SAVE_MONTH_SETTINGS",
            data: this.state.data
        };
        console.log(message);
        window.ws.send(JSON.stringify(message));
        this.updateCustomStateProperty('unsavedChanges', false)
    }
    updateWorker = (position, type, data) => {
        let workers = this.state.data.workers;
        workers[position][type] = data;
        this.setState({ data: { ...this.state.data, workers } });
        this.updateCustomStateProperty('unsavedChanges', true)

    }

    updateQuality = (type, data) => {
        let quality = this.state.data.quality;
        quality[type] = data;
        this.setState({ data: { ...this.state.data, quality } });
        this.updateCustomStateProperty('unsavedChanges', true)

    }
    updateService = (id, data) => {
        let services = this.state.data.services;
        services = services.map((value) => value.id === id ? data : value);
        this.setState({ data: { ...this.state.data, services } });
        this.updateCustomStateProperty('unsavedChanges', true)

    }
    renderData(data) {

        let content = ""
        switch (this.getCustomStateProperty('selectedTab')) {
            case 1:
                content = <ServicesSettings services={this.state.data.services} updateService={this.updateService} />
                break;
            case 2:
                content = <WorkersSettings workers={this.state.data.workers} updateWorker={this.updateWorker} />
                break;
            case 3:
                content = <QualitySettings quality={this.state.data.quality} updateQuality={this.updateQuality} />
        }

        return (
            <Box className="height100">
                <Box className="stickyHeader" alignItems="flex-end">
                    <Tabs
                        value={this.getCustomStateProperty('selectedTab')}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.changeTab}
                    >
                        <Tab value={1} label="Služby" />
                        <Tab value={2} label="Lidské zdroje" />
                        <Tab value={3} label="Ostatní" />
                    </Tabs>
                </Box>
                <Box className="adminMainContent" style={{ minHeight: 'calc(100% - 100px)' }}>
                    {content}
                </Box>
                <Box className="stickyFooter" alignItems="center" justifyContent="center">
                    <Button variant="contained" color="primary" className={this.getCustomStateProperty('unsavedChanges') ? 'shake' : ''} onClick={this.saveMonthlyEnters}>Uložit měsíční vstupy</Button>
                </Box>
            </Box>
        );
    }
}
MonthlyEntersForm.contextType = TimeContext;
export default MonthlyEntersForm;
