import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import ResponsiveDashboard from './ResponsiveDashboard';
import { withRouter } from "react-router";
import { AuthContext } from '../../../contexts/AuthProvider';
import TimeProvider from '../../../contexts/TimeProvider';

/**
 * Wrapper komponenta pro herni prehled
 */

class GameDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.gameId = props.match.params.id;
    }

    render() {
        return this.gameId ? (
            <TimeProvider><ResponsiveDashboard {...this.props.props} gameId={this.gameId} playerId={this.context.user.playerId} /></TimeProvider>
        ) : (
            <Grid
                container
                justify="center"
                alignItems="center"
                className="MainContent"
            >
                <Typography className="dots" variant="h2">
                    Načítání hry
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                </Typography>
            </Grid>
        );
    }
}
GameDashboard.contextType = AuthContext;
export default withRouter(GameDashboard);
