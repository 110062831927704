import React from 'react';
import { reduxForm, Form } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import LabelAndText from '../LabelAndText';
import LabelAndSelect from '../LabelAndSelect';
import { connect } from 'react-redux';
import { options0to100 } from '../../../../Modals/utils/reduxForm';

const QualityEquipmentHotel = ({ disable, handleSubmit, submitForm }) => {
    return (
        <Grid container item sm={12} className={'form-group'}>
            <Form
                className={'form-example'}
                onSubmit={handleSubmit(submitForm)}
            >
                <Typography
                    variant={'h5'}
                    color="textSecondary"
                    className={'txt-al--cen form-heading'}
                >
                    Kvalita vybavení hotelu
                </Typography>
                <LabelAndSelect
                    label={'Investice do vybavení hotelu v % z tržeb'}
                    labelGrid={6}
                    selectGrid={2}
                    disable={disable}
                    fieldName={'investment'}
                    options={options0to100}
                />
                <LabelAndText
                    label={'Úroveň vybavení hotelu'}
                    labelGrid={6}
                    textGrid={2}
                    disable={disable}
                    fieldName={'level'}
                />
            </Form>
        </Grid>
    );
};

export default connect()(reduxForm({})(QualityEquipmentHotel));
