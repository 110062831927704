import React from 'react';
import { Grid, LinearProgress } from '@material-ui/core';

/**
 * Super class for components that require data served from server via WebSocket.
 * Request for data is performed using this.event message.
 * Data for component is received in this.eventResponse message.
 */
class WSServedComponent extends React.Component {
    constructor(props) {
        super(props);

        /**
         * this.event = ""
         * this.eventData = ""
         * this.eventResponse
         * this.autoUpdatePeriod = def 0 = no periodic updates
         * */

        this.state = {
            data: [],
            haveData: false,
            customProperty: [],
        };
        this.autoUpdatePeriod = 0;
        this.ws = window.ws;
        this.updateTimer = null;

        this.renderData = this.renderData.bind(this);
        this.updateWSData = this.updateWSData.bind(this);
        this.updateCustomStateProperty = this.updateCustomStateProperty.bind(
            this,
        );
        this.getCustomStateProperty = this.getCustomStateProperty.bind(this);
    }

    componentDidMount() {
        this.ws.addEventListener('message', this.websocketUpdate);
        // send request right now
        this.sendInitData();
        if (this.autoUpdatePeriod > 0) {
            // setup timer for periodic updates (requests)
            this.updateTimer = setInterval(
                this.sendInitData,
                this.autoUpdatePeriod,
            );
        }
    }

    componentWillUnmount() {
        this.ws.removeEventListener('message', this.websocketUpdate);
        // clear periodic updates timer (in case one exists)
        clearInterval(this.updateTimer);
    }

    updateCustomStateProperty(property, value) {
        let customProperty = this.state.customProperty;
        customProperty[property] = value;
        this.setState({ customProperty: customProperty });
    }

    getCustomStateProperty(property) {
        return this.state.customProperty[property];
    }

    sendData = (data) => {
        this.ws.send(JSON.stringify(data));
    };

    sendInitData = () => {
        if (this.event === 'undefined') {
            return;
        }
        let data = '';
        if (this.eventData === 'undefined') {
            data = JSON.stringify({ event: this.event });
        } else {
            data = JSON.stringify({
                event: this.event,
                data: this.eventData,
            });
        }
        console.log(data);
        this.ws.send(data);
    };

    updateWSData(newData = null) {
        if (newData === null) {
            this.ws.send(
                JSON.stringify({
                    event: this.event,
                    data: this.eventData,
                }),
            );
        } else if (this.eventData === 'undefined') {
            this.ws.send(JSON.stringify({ event: this.event }));
        } else {
            this.ws.send(
                JSON.stringify({
                    event: this.event,
                    data: newData,
                }),
            );
        }
    }

    websocketDidUpdate(event) { }

    websocketUpdate = (event) => {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.event === this.eventResponse) {
            this.setState(
                {
                    data: data.data,
                    haveData: true,
                },
                () => {
                    this.websocketDidUpdate(event);
                },
            );
        }
    };

    renderData(data) {
        // to be overridden in a subclass
    }

    render() {
        if (this.state.haveData) {
            return this.renderData(this.state.data);
        }
        return (
            <Grid container style={{ padding: '3vh' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    Načítání komponentu {this.constructor.name}
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            </Grid>
        );
    }
}

export default WSServedComponent;
