import React, { Component } from 'react'

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Badge from '@material-ui/core/Badge';
import HomeIcon from '@material-ui/icons/Home';
import { Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { allGamesUrl, appUrl } from '../../utils/routesPath';
import DataMonitoringComponent from "../DataMonitoringComponent";
import SecuredRoute from "../../routes/SecuredRoute";
import { AuthContext } from '../../contexts/AuthProvider';
import { Link } from 'react-router-dom';
/**
 * Komponenta navigacniho menu
 */

export default class Navigation extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            classes, dispatch, history,
        } = this.props;
        return (

            <>
                {this.context.isLoggedIn && (<AppBar position="relative" className={classes.appBar} style={{ zIndex: 10 }}>
                    <Toolbar
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <div className="FlexCenter">
                            <IconButton
                                component={Link}
                                to={allGamesUrl}
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="menu"
                            >
                                <HomeIcon fontSize="large" />
                            </IconButton>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                className={classes.title}
                            >
                                Hotelový simulátor
                            </Typography>

                        </div>
                        <div>
                            <SecuredRoute path={`${appUrl}/:id`}>
                                <DataMonitoringComponent />
                            </SecuredRoute>
                        </div>
                        <div>
                            {this.context.isAdmin && (
                                <Button
                                    color="inherit"
                                    component={Link}
                                    to={'/admin/games'}
                                >
                                    <SettingsIcon />
                                    Administrace
                                </Button>
                            )}

                            <Button
                                onClick={() => this.context.logout()}
                                color="inherit">
                                <ExitToAppIcon />
                                Odhlásit se
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar >)
                }
            </>
        )
    }
}
Navigation.contextType = AuthContext;