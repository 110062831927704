import React, { Component } from 'react'
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.cs';
import WSServedComponent from '../../websocketService/WSServedComponent';
import { TimeContext } from '../../contexts/TimeProvider';
import moment from 'moment';
import { Box, MenuItem, Popover, Popper, Select, Typography } from '@material-ui/core';
import { getDataForPeriodSelect } from '../../utils/reportsUtils';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: '5px 10px'
    },
});

class MarketEvents extends WSServedComponent {
    constructor(props, context) {
        super(props);
        this.state = {
            popover: {
                open: false,
                anchorEl: false,
                content: '',
            },
            year: context.time.year
        };
        this.event = 'GET_EVENTS';
        this.eventResponse = 'EVENT_LIST';
    }
    setYear = (e) => {
        this.setState({ year: e.target.value });
    }
    handleDayMouseEnter(e) {
        if (e.events.length > 0) {
            var content = (
                <div>
                    {e.events.map((event, e_index) => (
                        <div key={e_index}>
                            <strong>{event.name}</strong><br />
                            {(event.startDate.getTime() !== event.endDate.getTime() ? (moment(event.startDate).format('D.') + ' - ') : '') + moment(event.endDate).format('D.M.YYYY')}
                        </div>))}
                </div>
            );
            this.setState({
                popover: {
                    open: true,
                    anchorEl: e.element.firstChild,
                    content: content,
                }
            })

        }
    }

    handleDayMouseLeave() {
        this.setState({
            popover: {
                open: false,
                anchorEl: false,
                title: '',
                text: ''
            }
        });
    }

    renderData(data) {
        const { classes } = this.props;
        let events = data.map((event) => {
            return {
                name: event.name[0].code === 'cs' ? event.name[0].value : event.name[1].value,
                startDate: new Date(event.dateStart),
                endDate: new Date(event.dateEnd),
                eventType: event.eventType,
                color: event.eventType == 1 ? '#fbe5d6' : '#deebf7'
            }
        });
        return (
            <div className="eventCalendar">
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="subtitle2" style={{ marginRight: 10, fontSize: '1.1rem' }}>Rok: </Typography>
                    <Select value={this.state.year} onChange={this.setYear}>
                        {getDataForPeriodSelect(this.context.time.startDate, this.context.time.endDate, 'monthly').map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </Box>
                <Calendar
                    displayHeader={false}
                    year={this.state.year}
                    defaultYear={this.context.time.year}
                    style="background"
                    dataSource={events}
                    minDate={new Date(this.context.time.startDate)}
                    maxDate={new Date(this.context.time.endDate)}
                    language="cs"
                    onDayEnter={e => this.handleDayMouseEnter(e)}
                    onDayLeave={() => this.handleDayMouseLeave()}
                ></Calendar>
                <Box 
                display='flex'
                justifyContent="center">
                    <Box className='EventsLegend'>
                        <div className='EventsLegendIcon' style={{ backgroundColor: '#fbe5d6' }}></div>
                        <div className='EventsLegendTitle'>Událost</div>
                    </Box>
                    <Box className='EventsLegend'>
                        <div className='EventsLegendIcon' style={{ backgroundColor: '#deebf7' }}></div>
                        <div className='EventsLegendTitle'>Svátek</div>
                    </Box>
                </Box>
                <Popover
                    open={this.state.popover.open}
                    anchorEl={this.state.popover.anchorEl}
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {this.state.popover.content}
                </Popover>
            </div>
        )
    }
}
MarketEvents.propTypes = {
    classes: PropTypes.object.isRequired
}
MarketEvents.contextType = TimeContext;
export default withStyles(styles)(MarketEvents);