import React from 'react';
import { reduxForm } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { validateRequired } from '../../../../Modals/utils/validations';
import LabelAndSelect from '../LabelAndSelect';
import LabelAndText from '../LabelAndText';
import { options0to100 } from '../../../../Modals/utils/reduxForm';

const QualityOfRawRooms = ({ disable }) => (
    <Grid container item sm={12} className={'form-group'}>
        <form className={'form-example'}>
            <Typography
                variant={'h5'}
                color="textSecondary"
                className={'txt-al--cen form-heading'}
            >
                Pracovníci recepce
            </Typography>
            <LabelAndSelect
                label={'Junior'}
                labelGrid={7}
                selectGrid={5}
                disable={disable}
                fieldName={'value'}
                options={options0to100}
            />

            <LabelAndText
                label={'Náklady snídaně'}
                labelGrid={7}
                textGrid={5}
                disable={disable}
            />
            <LabelAndText
                label={'Náklady skupinové večeře'}
                labelGrid={7}
                textGrid={5}
                disable={disable}
            />
            <LabelAndText
                label={'Náklady á la carte'}
                labelGrid={7}
                textGrid={5}
                disable={disable}
            />
            <LabelAndText
                label={'Náklady lobby'}
                labelGrid={7}
                textGrid={5}
                disable={disable}
            />
        </form>
    </Grid>
);
export default reduxForm({
    form: 'MessRoomsWorkers',
    validate: validateRequired(),
})(QualityOfRawRooms);
