import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { DeleteForever } from '@material-ui/icons';
import red from '@material-ui/core/colors/red';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        backgroundColor: red[800],
        color: '#FFFFFF',
        "&:hover": {
            backgroundColor: red[900],
        }
    }
}));

export default function DeleteButton(props) {
    const classes = useStyles();
    let {warning, warningMessage, onClick, ...passDownProps} = props;
    return (
        <Button
            variant="contained"
            startIcon={<DeleteForever />}
            className={classes.button}
            {...passDownProps}
            onClick={() => {
                if (onClick) {
                    if (!warning || (warning && confirm(warningMessage))) {
                        onClick();
                    }
                }
            }}
        >
            {props.children}
        </Button>
    );
}
DeleteButton.propTypes = {
    warning: PropTypes.bool,
    warningMessage: PropTypes.string
}
DeleteButton.defaultProps = {
    warning: false,
    warningMessage: 'Opravdu si přejete provést tuto akci?'
}