import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DemandsSideBar from '../../../containers/GameDashBoard/DemandsSideBar';
import HandsonTable from '../../../components/HadnsoneTable/HandsonTable';
import MonthlyEntersForm from '../../../containers/Forms/App/MonthlyEnters';
import MarketResearch from  '../../../containers/MarketResearch'
import NewCycleUserSetting from '../../../containers/NewCycleUserSetting';
import WSServedComponent from '../../../websocketService/WSServedComponent';
import Tab from '../../../components/AppPage/Tab';
import UserReports from '../../../containers/UserReports';
import { AuthContext } from '../../../contexts/AuthProvider';
import { TimeContext } from '../../../contexts/TimeProvider';
/**
 * Komponenta/obrazovka s aktualnim hernim prehledem (hrou), URL: /game/....gameId....
 */

const drawerWidth = 240;
const useStyles = (theme) => ({
    root: {
        display: 'flex',
        width: 'inherit',
    },
    appBar: {
        zIndex: theme.zIndex.backDrop + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toolBarContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        //  ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        display: "block",
        float: "left",
        width: "calc(100% - 55px)",
        height: "100%"
        //  padding: theme.spacing(3),
    },
});

class Dashboard extends WSServedComponent {
    constructor(props) {
        super(props);

        this.event = 'GET_GAME_DATA';
        this.eventResponse = 'GAME_HERE';
        this.eventData = { id: props.gameId };

        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.websocketDidUpdate = this.websocketDidUpdate.bind(this);
    }

    componentDidMount() {
        this.updateCustomStateProperty("activeTab", 1);
        this.sendData({
            event: 'CONNECT_TO_GAME',
            data: {
                playerId: this.props.playerId,
                gameId: this.props.gameId,
            },
        });
        super.componentDidMount();
    }

    websocketDidUpdate() {
        this.context.setTime({
            startDate: this.state.data.startDate,
            endDate: this.state.data.endDate,
            currentTime: this.state.data.currentTime,
            secForRound: this.state.data.secForRound,
            round: this.state.data.state.cycle,
            day: this.state.data.state.day,
            month: this.state.data.state.month,
            year: this.state.data.state.year,
            status: this.state.data.state.status
        });
    }

    handleDrawerToggle() {
        this.setState({ open: true });
    }

    handleChangeTab = (tab) => {
        this.updateCustomStateProperty("activeTab", tab)
    }

    renderData(data) {
        const { classes } = this.props;

        var content = <div>none</div>
        switch (this.getCustomStateProperty("activeTab")) {
            case 1:
                content = <HandsonTable endDate={data.endDate} startDate={data.startDate} selectedMonth={data.state.month} />;
                break;
            case 2:
                content = <MonthlyEntersForm hotelId={this.hotelId} currentMonth={data.state.month} />;
                break;
            case 3:
                content = <UserReports />;
                break;
            case 4:
                content = <MarketResearch/>;
                break;   
        }

        return this.context.time.status === 'RUNNING' || this.context.time.status === 'STOPPED' || this.context.time.status === 'ADMIN_PAUSED' || this.context.time.status === 'END_MONTH_PAUSED' ? (
            <div className={classes.root}>
                <NewCycleUserSetting />
                <Paper elevation={3} style={{ margin: '0.8rem', width: '100%', height: "calc(100vh - 65px - 2rem)", overflow: 'hidden' }}>
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        style={{ width: 55, float: "left", display: "block", height: "100%" }}
                    >
                        <Tab value={this.getCustomStateProperty("activeTab")} tabChange={this.handleChangeTab} id={1}>
                            Denní vstupy
                        </Tab>
                        <Tab value={this.getCustomStateProperty("activeTab")} tabChange={this.handleChangeTab} id={2}>
                            Měsíční vstupy
                        </Tab>
                        <Tab value={this.getCustomStateProperty("activeTab")} tabChange={this.handleChangeTab} id={3}>
                            Výkazy
                        </Tab>
                        <Tab value={this.getCustomStateProperty("activeTab")} tabChange={this.handleChangeTab} id={4}>
                            Průzkum trhu
                        </Tab>
                        <Tab value={this.getCustomStateProperty("activeTab")} tabChange={this.handleChangeTab} id={5}>
                            O Hotelu
                        </Tab>
                    </Grid>
                    <div className={classes.content}>
                        <div className={classes.toolBarContent} />
                        <Grid container style={{ height: "100%" }}>
                            <Grid item ref={200} className="center-table-definition" style={{ width: 'inherit', position: "inherit", height: "100%", overflowY: "auto" }}>
                                {content}
                            </Grid>
                            <Grid style={{ height: '100%' }} className="left-bar-definition">
                                <DemandsSideBar 
                                    hotelId={this.hotelId}       
                                    demands={[]}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </div>
        ) : (
            <Grid
                container
                justify="center"
                alignItems="center"
                className="MinHeight"
            >
                <Typography className="dots" variant="h2">
                    Hra nezačala, vyčkejte na spuštění
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                </Typography>
            </Grid>
        );
    }
}
Dashboard.contextType = TimeContext;
export default withStyles(useStyles)(Dashboard);