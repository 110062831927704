import { Grid, Table, TableBody, TableRow, TableCell, Typography, Select, MenuItem, Box, TableContainer } from '@material-ui/core';
import React, { Component } from 'react'
import ExpensesAndGainsChart from '../Charts/ExpensesAndGainsChart';
import moment from 'moment';
import { getDataForPeriodSelect, prepareArrayForMonthlyChart, prepareArrayForDailyChart } from '../../utils/reportsUtils';
const data = [
    {
        name: 1,
        accommodationGains: 200,
        otherGains: 200,
        personalExpenses: 50,
        materialExpenses: 200,
        equipmentExpenses: 150,
        marketingExpenses: 350,
        servicesExpenses: 100,
        otherExpenses: 100
    },
    {
        name: 2,
        accommodationGains: 300,
        otherGains: 400,
        personalExpenses: 45,
        materialExpenses: 150,
        equipmentExpenses: 200,
        marketingExpenses: 150,
        servicesExpenses: 50,
        otherExpenses: 50
    },
    {
        name: 3,
        accommodationGains: 50,
        otherGains: 0,
        personalExpenses: 150,
        materialExpenses: 124,
        equipmentExpenses: 525,
        marketingExpenses: 123,
        servicesExpenses: 450,
        otherExpenses: 100
    },
    {
        name: 4,
        accommodationGains: 500,
        otherGains: 300,
        personalExpenses: 50,
        materialExpenses: 50,
        equipmentExpenses: 100,
        marketingExpenses: 450,
        servicesExpenses: 45,
        otherExpenses: 100
    },
    {
        name: 5,
        accommodationGains: 400,
        otherGains: 100,
        personalExpenses: 100,
        materialExpenses: 50,
        equipmentExpenses: 80,
        marketingExpenses: 125,
        servicesExpenses: 456,
        otherExpenses: 100
    }
];
export default class ExpensesAndGainsReport extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            data: data
        }
    }
  
    processExpensesAndGains(data) {
        let cumulativeGains = 0;
        let cumulativeExpenses = 0;
        let [totalPersonalExpenses,
            totalMaterialExpenses,
            totalEquipmentExpenses,
            totalMarketingExpenses,
            totalServicesExpenses,
            totalOtherExpenses,
            totalAccommodationGains,
            totalOtherGains] = [0, 0, 0, 0, 0, 0, 0, 0];
        let chartData = this.props.reportType === 'monthly' ? prepareArrayForMonthlyChart() : prepareArrayForDailyChart();
        data.forEach(item => {
            let totalGains = item.accommodationGains + item.otherGains;
            let totalExpenses = item.personalExpenses + item.materialExpenses + item.equipmentExpenses + item.marketingExpenses + item.servicesExpenses + item.otherExpenses;
            cumulativeGains += totalGains;
            cumulativeExpenses += totalExpenses;

            totalAccommodationGains += item.accommodationGains;
            totalOtherGains += item.otherGains;

            totalPersonalExpenses += item.personalExpenses;
            totalMaterialExpenses += item.materialExpenses;
            totalEquipmentExpenses += item.equipmentExpenses;
            totalMarketingExpenses += item.marketingExpenses;
            totalServicesExpenses += item.servicesExpenses;
            totalOtherExpenses += item.otherExpenses;

            chartData[item.name - 1] = {
                ...item,
                ...chartData[item.name - 1],
                totalGains: totalGains,
                totalExpenses: totalExpenses,
                cumulativeGains: cumulativeGains,
                cumulativeExpenses: cumulativeExpenses
            }
        });

        return {
            chartData,
            totalPersonalExpenses,
            totalMaterialExpenses,
            totalEquipmentExpenses,
            totalMarketingExpenses,
            totalServicesExpenses,
            totalOtherExpenses,
            totalAccommodationGains,
            totalOtherGains,
            totalExpenses: cumulativeExpenses,
            totalGains: cumulativeGains
        }
    }
    render() {
        let reportData = {};
        reportData = this.processExpensesAndGains(this.state.data);
        console.log(reportData);
        return (
            <Grid container className="adminMainContent" style={{border: "1px solid #d3d3d36b"}}>
                <Grid item xs={3}>
                    <Typography className="ReportsTableTitle" variant="h5">Náklady: </Typography>
                    <Table size="small" className="ReportsTable">
                        <TableBody>
                            <TableRow>
                                <TableCell>Personální</TableCell>
                                <TableCell>{reportData.totalPersonalExpenses}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Materiál</TableCell>
                                <TableCell>{reportData.totalMaterialExpenses}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Vybavení</TableCell>
                                <TableCell>{reportData.totalEquipmentExpenses}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Propagace</TableCell>
                                <TableCell>{reportData.totalMarketingExpenses}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Služby</TableCell>
                                <TableCell>{reportData.totalServicesExpenses}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ostatní</TableCell>
                                <TableCell>{reportData.totalOtherExpenses}</TableCell>
                            </TableRow>
                            <TableRow className="SummaryRow Expenses">
                                <TableCell>Celkem</TableCell>
                                <TableCell>{reportData.totalExpenses}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Typography className="ReportsTableTitle" variant="h5">Výnosy: </Typography>
                    <Table size="small"  className="ReportsTable">
                        <TableBody>
                            <TableRow>
                                <TableCell>Ubytování</TableCell>
                                <TableCell>{reportData.totalAccommodationGains}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ostatní</TableCell>
                                <TableCell>{reportData.totalOtherGains}</TableCell>
                            </TableRow>
                            <TableRow  className="SummaryRow Gains">
                                <TableCell>Celkem</TableCell>
                                <TableCell>{reportData.totalGains}</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={9}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="subtitle2" style={{ marginRight: 10, fontSize: '1.1rem'}}>{this.props.reportType === 'monthly' ? "Rok: " : "Měsíc: "}</Typography>
                        <Select value={this.props.reportType === 'monthly' ? this.props.time.year : this.props.time.month}>
                            {getDataForPeriodSelect(this.props.time.startDate, this.props.time.endDate, this.props.reportType).map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <ExpensesAndGainsChart data={reportData.chartData} />
                </Grid>
            </Grid >
        )
    }
}
