import WSServedComponent from "../../websocketService/WSServedComponent";
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Box, Popover } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { withRouter } from "react-router";
import WSUpdatedComponent from "../../websocketService/WSUpdatedComponent";

class DataMonitoringComponent extends WSUpdatedComponent {

    constructor(props) {
        super(props);
        this.eventUpdate = "HOTEL_UPDATE";
        this.dataIDs = ['quality', 'satisfaction'];
    }


    prepareData(data) {
        let array = [];
        for (const [key, value] of Object.entries(data)) {
            array[key] = value;
        }
        console.log("SERVER UPDATE CONSOLE");
        console.log(array);
        return array
    }

    render() {

        return (
            <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <div>
                        <IconButton color="inherit">
                            <Badge badgeContent={0} color="secondary">
                                <AssessmentIcon {...bindTrigger(popupState)}

                                />
                            </Badge>
                        </IconButton>
                        <Popover
                            style={{ zIndex: 1302 }}
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box p={2}>
                                {this.state.haveData ?
                                    Object.keys(this.state.data).map((key, value) =>
                                        <Typography key={value}>{key}  = {this.state.data[key]}</Typography>
                                    ) : "No Data"}
                            </Box>
                        </Popover>
                    </div>
                )}
            </PopupState>);

    }
}
export default withRouter(DataMonitoringComponent);
