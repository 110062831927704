import { Paper, Box } from '@material-ui/core';
import React, { Component } from 'react'
import HotTableWrapper from '../../components/HadnsoneTable/HotTableWrapper';
import { TimeContext } from '../../contexts/TimeProvider';
import WSServedComponent from '../../websocketService/WSServedComponent'
import ArrowMonths from '../ArrowsMonths';

export default class MarketRoomCosts extends WSServedComponent {
    constructor(props, context) {
        super(props);
        this.state = { ...this.state};
        this.event = 'GET_COMP_PRICES'; //TODO vlastni event
        this.eventResponse = 'COMP_PRICES_HERE';
        this.eventData = {
            month: context.time.month
        };
        console.log(this.eventData);
        this.tableWidth = 'calc(100% - 40px)';
    }
    componentDidMount(){
        super.componentDidMount();
        this.updateCustomStateProperty('month', this.context.time.month );
    }
    setMonth = (month) => {
        this.updateCustomStateProperty('month', month );
        this.updateWSData(
            {
                month: month,
            }
        )
    }
    websocketDidUpdate() {
        if (this.state.data.month !== this.state.month) {
            this.updateCustomStateProperty('month', this.state.data.month );
        }
    }
    renderData(data) {
        let headers = [];
        let prices = [];
        Object.values(data).forEach(hotel => {
            headers.push(hotel.name);
            prices.push(hotel.prices);
        });
        return (
            <Box>
                <ArrowMonths
                    setMonth={this.setMonth}
                    month={this.getCustomStateProperty('month')}
                />
                <Paper style={{ width: this.tableWidth }} className="HotTableWrap">
                    <HotTableWrapper
                        tableId="pricesTable"
                        data={prices}
                        rowHeaders={headers}
                        tableWidth={this.tableWidth}
                        colorStyle="blue"
                        className="Table"
                        month={this.getCustomStateProperty('month')}
                        monthChangesData={[]}
                        readOnly
                    />
                </Paper>
            </Box>
        )
    }
}
MarketRoomCosts.contextType = TimeContext;