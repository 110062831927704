import React from 'react';
import './scss/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import {
    Switch,
    Route, Redirect, BrowserRouter,
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import {appUrl, loginUrl} from './utils/routesPath';
import AdminDashboard from './pages/Admin/index';
import Games from './pages/Games';
import Login from './pages/Login';
import GameDashboard from './pages/Game/GameDashBoard/GameDashboard';
import Navigation from './components/NavigationV02';
import LoginRoute from './routes/LoginRoute';
import SecuredRoute from './routes/SecuredRoute';
import SnackbarService from "./services/SnackbarService";
import AuthProvider, { AuthContext } from './contexts/AuthProvider';


/**
 * Definice stylu
 */

const drawerWidth = 240;
const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: 1301,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toolBarContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
    },
});

class Routes extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        return (
            <BrowserRouter>
                <LastLocationProvider>
                    <AuthProvider>
                        <CssBaseline />
                        <Navigation classes={classes} user={{ roles: [2] }}/>
                        <SnackbarService />
                        <div>
                            <Switch>
                                <LoginRoute path={loginUrl}>
                                    <Login/>
                                </LoginRoute>


                                <SecuredRoute path={"/games"}>
                                    <Games/>
                                </SecuredRoute>

                                <SecuredRoute path={`${appUrl}/:id`}>
                                    <GameDashboard />
                                </SecuredRoute>

                                <Route path={"/admin"}> 
                                    <AdminDashboard />
                                </Route>
                                <SecuredRoute path={"/"}>
                                    {this.context.isAdmin ? (
                                        <Redirect to="/admin" />
                                    ) : (
                                        <Redirect to="/games" />
                                    )
                                    }
                                </SecuredRoute>

                            </Switch>
                        </div>
                    </AuthProvider>
                </LastLocationProvider >
            </BrowserRouter >
        );
    }
}

Routes.contextType = AuthContext;
export default withStyles(useStyles)(Routes);