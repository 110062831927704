import { Grid, Typography } from '@material-ui/core';
import { reduxForm, Form } from 'redux-form';
import React from 'react';
import LabelAndText from '../LabelAndText';
import { connect } from 'react-redux';

const SpendingOnPromotion = ({ disable, handleSubmit, submitForm }) => {
    return (
        <Grid container item sm={12} className={'form-group'}>
            <Form
                className={'form-example'}
                onSubmit={handleSubmit(submitForm)}
            >
                <Typography
                    variant={'h5'}
                    color="textSecondary"
                    className={'txt-al--cen form-heading'}
                >
                    Výdaje na propagaci
                </Typography>
                <LabelAndText
                    label={'Náklady snídaně'}
                    labelGrid={6}
                    textGrid={2}
                    disable={disable}
                    fieldName="investment"
                />
            </Form>
        </Grid>
    );
};

export default connect()(reduxForm({})(SpendingOnPromotion));
