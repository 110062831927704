import React from 'react';
import {
    Button,
    MenuItem,
    Grid,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import WSForm from '../../websocketService/WSForm';
import GameHotelsTable from "./GameHotelsTable";
import { cs } from 'date-fns/locale';
import SaveIcon from '@material-ui/icons/Save';

class EditGameCommonComponent extends WSForm {
    constructor(props) {
        super(props);
        this.formId = 'SetGameCommonForm';
        // param gameData form data
        // v headeru token Bearer

    }
    componentWillUnmount() {
        window.ws.removeEventListener('message', this.websocketUpdate);
    }
    componentDidMount() {
        window.ws.addEventListener('message', this.websocketUpdate);
        if (this.props.id) {
            this.setFormData("id", this.props.id);
            this.setFormData("gameSettingId", this.props.gameSettingId);
            this.setFormData("startDate", this.props.gameStart || this.formatDate(new Date((new Date().getFullYear()), 0, 1)));
            this.setFormData("endDate", this.props.gameEnd || this.formatDate(new Date((new Date().getFullYear()), 11, 1)));
            this.setFormData("capacity", this.props.capacity || 100);
            this.setFormData("currency", this.props.currency || 'EUR');
        }
        setTimeout(this.getFormDefaultData, 100)
    }

    getFormDefaultData = () => {
        if (this.props.id) {
            var message = {
                event: "GET_GAME_DATA",
                data: { id: this.props.id }
            };
            window.ws.send(JSON.stringify(message));
        }
    }

    websocketUpdate = (event) => {
        const data = JSON.parse(event.data);
        if (data.event === "GAME_HERE") {
            let formData = [];
            Object.keys(data.data).map((key) => formData[key] = data.data[key]);
            this.setState({ "form-values": formData });
        }

        if (data.event === "GAME_INITIALIZED") {
            this.setFormData("id", data.data.id)
        }
    }



    createData = (id, hotelName, playerName, capacity, hotelBrandLevel) => {
        return {
            id: id,
            hotelName: hotelName,
            playerName: playerName,
            capacity: capacity,
            hotelBrandLevel: hotelBrandLevel
        };
    }


    deleteRow = (id) => {

    }


    saveGame = () => {
        {
            let gameSettingsId = this.getFormValue("gameSettingId")
            let gameId = this.getFormValue("id")
            var message = {
                event: "INITIALIZE_GAME",
                data: {
                    id: gameId,
                    gameSettingId: gameSettingsId,
                    gameName: this.getFormValue("gameName"),
                    capacity: this.getFormValue('capacity'),
                    currency: this.getFormValue('currency'),
                    setting: {
                        gameStart: this.formatDate(this.getFormValue('startDate')),
                        gameEnd: this.formatDate(this.getFormValue('endDate')),// začínající den v měsící
                        smallCycleTimeMs: this.getFormValue('secForRound'), // delka maleho cyklu
                        initialBudget: 100, // výchozí rozpočet
                    }
                }
            };
            console.log(message);
            window.ws.send(JSON.stringify(message));
        }
    }


    formatDate = (date) => {
        date = new Date(date);
        let year = date.getFullYear() // 2019
        let day = this.zeroPad(date.getDate())
        let month = this.zeroPad(date.getMonth() + 1)
        return String(year) + "-" + month + "-" + day
    }

    zeroPad = (num) => String(num).padStart(2, '0')
    render() {
        return (
            <div>
                <div className="editGameForm">
                    <Typography className="adminPageSubtitle" variant="h4">Obecné nastavení</Typography>
                    {this.getFormValue("gameSettingId") ?

                        <Grid>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                            >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2">Název hry</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField name="gameName" value={this.getFormValue('gameName') || ''} onChange={this.onChange} />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2">Kapacita</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        className="readonly"
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        type="number"
                                        name="capacity"
                                        value={this.getFormValue('capacity') || 100}
                                        onChange={this.onChange} />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2">Měna</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Select
                                        className="readonly"
                                        name="currency"
                                        inputProps={{ readOnly: true }}
                                        value={this.getFormValue('currency') || "EUR"}
                                        onChange={this.onChange}
                                    >
                                        <MenuItem value="CZK">CZK</MenuItem>
                                        <MenuItem value="EUR">EUR</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2">Období hry od - do</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <MuiPickersUtilsProvider locale={cs} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            name="startDate"
                                            label="Od"
                                            format="dd. MM. yyyy"
                                            maxDate={this.getFormValue('endDate') || false}
                                            value={this.getFormValue('startDate') || ""}
                                            onChange={date => {
                                                this.onChange({ target: { name: "startDate", value: date } })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <KeyboardDatePicker
                                            margin="normal"
                                            name="endDate"
                                            value={this.getFormValue('endDate') || ""}
                                            label="Do"
                                            format="dd. MM. yyyy"
                                            onChange={date => {
                                                this.onChange({ target: { name: "endDate", value: date } })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2">Délka denního cyklu</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        type="number"
                                        name="secForRound"
                                        value={this.getFormValue('secForRound')}
                                        onChange={this.onChange} />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" justify="center" style={{ marginTop: '1rem' }}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.saveGame}
                                    startIcon={<SaveIcon />}
                                >
                                    Uložit nastavení hry
                                </Button>
                            </Grid>
                        </Grid>

                        : ""}
                </div>
                {this.getFormValue("id") ?
                    <GameHotelsTable gameId={this.getFormValue("id")} />
                    : ""}
            </div>
        );
    }
}
export default EditGameCommonComponent;
