import { Field } from 'redux-form';
import { Grid } from '@material-ui/core';
import React from 'react';

const LabelAndText = ({ label, labelGrid, textGrid, disable, fieldName }) => {
    return (
        <Grid container justify={'center'} item sm={12} className={'form-group'}>
            <Grid container justify={'flex-end'} sm={labelGrid}>
                <label className={'form-label'} style={{ width: 'auto' }}>
                    {label}
                </label>
            </Grid>
            <Grid
                container
                justify={'flex-start'}
                sm={textGrid}
                item
                className={'form-padd'}
            >
                <Field
                    name={fieldName}
                    component="input"
                    type="text"
                    className={'form-control'}
                    placeholder={label}
                    disabled={disable}
                ></Field>
            </Grid>
        </Grid>
    );
};

export default LabelAndText;
