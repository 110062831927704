import React from 'react';
import { Field } from 'redux-form';
import { Grid } from '@material-ui/core';
import { options0to100 } from '../../../Modals/utils/reduxForm';

const LabelAndSelectAndText = ({
    label,
    nameSelect,
    nameText,
    namePlaceholder,
}) => {
    return (
        <Grid container item sm={12} className={'form-group'}>
            <Grid item sm={4}>
                <label className={'form-label'}>{label}</label>
            </Grid>
            <Grid item sm={3} className={'form-padd'}>
                <Field
                    name={nameSelect}
                    component={'select'}
                    className={'form-control'}
                    placeholder="Count"
                    disabled
                >
                    {options0to100()}
                </Field>
            </Grid>
            <Grid item sm={5} className={'form-padd'}>
                <Field
                    name={nameText}
                    component="input"
                    type="text"
                    className={'form-control'}
                    placeholder={namePlaceholder}
                    disabled
                />
            </Grid>
        </Grid>
    );
};

export default LabelAndSelectAndText;
