import React, { Component } from 'react'
import { withRouter } from 'react-router';

export const AuthContext = React.createContext({ user: false, isLoggedIn: () => { } });
export const LoginContext = React.createContext()
class AuthProvider extends Component {
    constructor(props) {
        super(props);
        let user = false;
        if (this.getCookie('playerId')) {
            user = {
                playerId: this.getCookie('playerId'),
                role: this.getCookie('playerRole')
            }
        } else {
            window.localStorage.removeItem('playerId');
        }
        this.state = {
            user: user
        }
        this.websocketUpdate = this.websocketUpdate.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
    }

    componentDidMount() {
        window.ws.addEventListener('message', this.websocketUpdate);
    }
    componentWillUnmount() {
        window.ws.removeEventListener('message', this.websocketUpdate);
    }

    isAdmin() {
        return this.state.user && this.state.user.role === 'ADMIN';
    }
    isLoggedIn() {
        return (this.state.user && this.state.user !== false);
    }
    setUser = (playerId, role) => {
        this.setCookie("playerId", playerId, 1);
        this.setCookie("playerRole", role, 1);
        window.localStorage.setItem('playerId', playerId);
        this.setState({ user: { playerId: playerId, role: role } });
    }
    removeUser = () =>{
        this.setCookie("playerId", "", -1);
        this.setCookie("playerRole", "", -1);
        this.setState({ user: false });
        window.localStorage.removeItem('playerId');
    } 
    loginUser = (name, password, errorfc) => {
        window.ws.send(
            JSON.stringify(
                {
                    event: "AUTHORIZE_USER",
                    data: {
                        name: name,
                        password: password
                    }
                }
            )
        );
        console.log('auth');

        this.errorfc = errorfc
    }
   
    logoutUser = () => {
        this.removeUser();
        window.ws.send(JSON.stringify({ event: "USER_LOGOUT" }))
        window.snackbarService.sendMessage("success", "Odhlášení proběhlo uspešně!")
    }

    websocketUpdate = (event) => {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.event === "USER_AUTHORIZED") {
            if (data.data !== null) {
                this.setUser(data.data.playerId, data.data.playerRole);
                let redirectUrl = '/games';
                if (data.data.playerRole === "ADMIN") {
                    redirectUrl = "/admin/games";
                }
                this.props.history.push(redirectUrl);

                window.snackbarService.sendMessage("success", "Přihlášení proběhlo uspešně!")
            } else {
                this.errorfc();
            }
        } else if (data.event === "PLAYER_LOGGED_OUT") {
            this.removeUser();
            window.snackbarService.sendMessage("success", "Odhlášení proběhlo uspešně!")
            this.props.history.push("/");
            //window.location.href = "/"; //TODO: provizorni nez se vyresi spravne odhlaseni.
        } if (data.event === "PLAYER_LOGIN_ERROR") {
            this.errorfc();
        }
    }

    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }

    getCookie(cname) {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split('; ');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                let value = c.substring(name.length, c.length);
                return value
            }
        }
        return null;
    }


    render() {
        return (
            <AuthContext.Provider value={{user: this.state.user, isLoggedIn: this.isLoggedIn(), isAdmin: this.isAdmin(), login: this.loginUser, logout: this.logoutUser}}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

export default withRouter(AuthProvider)
