import React from 'react'
import WSForm from "../../websocketService/WSForm";
import { Checkbox, FormControl, FormControlLabel, Grid, Input, InputLabel, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

class AddNewUserForm extends WSForm {


    constructor(props) {
        super(props);


    }

    saveUser = () => {

        let event = "CREATE_USER";
        if (this.getFormValue("admin")) {
            event = "CREATE_ADMIN"
        }

        var message = {
            event: event,
            data: {
                user: {
                    name: this.getFormValue("name"),
                    password: this.getFormValue("password"),
                    email: this.getFormValue("email")
                }
            }
        };
        window.ws.send(JSON.stringify(message));
        this.props.closeModal();
    }

    render() {
        return (
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Typography variant="h4" align="center">Nový uživatel</Typography>
                </Grid>
                <Grid item sm={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="my-input">Jmeno</InputLabel>
                        <Input type={"text"} value={this.getFormValue("name")} name={"name"} onChange={this.onChange} />
                    </FormControl>
                </Grid>
                <Grid item sm={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="my-input">E-mail</InputLabel>
                        <Input type={"email"} value={this.getFormValue("email")} name={"email"} onChange={this.onChange} />
                    </FormControl>
                </Grid>
                <Grid item sm={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="my-input">Heslo</InputLabel>
                        <Input type={"password"} value={this.getFormValue("password")} name={"password"} onChange={this.onChange} />
                    </FormControl>
                </Grid>
                <Grid item sm={12}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.getFormValue("admin")}
                                    onChange={this.onChange}
                                    name="admin"
                                    color="primary"
                                />
                            }
                            label="Administrátor"
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={12} style={{ textAlign: 'center' }}>
                    <Button onClick={this.saveUser} variant="contained" color="primary" style={{ margin: "0 .5rem" }}>
                        Uložit
                    </Button>
                    <Button onClick={this.props.closeModal} variant="contained" style={{ margin: "0 .5rem" }}>Zavřít</Button>
                </Grid>
            </Grid>
        )
    }

}
export default AddNewUserForm;
