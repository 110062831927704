import React from 'react';
import {
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    MenuItem, Select,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { isEmpty, isNil } from 'ramda';
import { adminDashboardUrl, appUrl } from '../../utils/routesPath';
import WSServedComponent from '../../websocketService/WSServedComponent';
import DeleteButton from '../../components/Buttons/DeleteButton';
import EditButton from '../../components/Buttons/EditButton';
import { Link } from 'react-router-dom';

import GAME_STATUSES from '../../constants/gameStatuses';
/**
 * Komponenta/obrazovka s prehledem vsech her (pro hrace dostupnych her)
 */

class AdminGames extends WSServedComponent {
    constructor() {
        super();
        this.autoUpdatePeriod = 2000;
        this.event = 'GET_ALL_GAMES';
        this.eventResponse = 'GAMES_HERE';
    }

    changeGameStatus = (data, id) => {
        let event = "";
        switch (data.target.value) {


            case "STOPPED":
                event = "STOP_GAME"
                break
            case "RUNNING":
                event = "START_GAME"
                break

            case "ADMIN_PAUSED":
                event = "PAUSE_GAME"
                break

        }


        let message = {
            event: event,  // nebo START_GAME
            data: {
                id: id
            }
        };
        this.sendData(message)
    }

    deleteGame(id) {
        let message = {
            event: "DELETE_GAME",  // nebo START_GAME
            data: {
                id: id
            }
        };
        this.sendData(message);
    }

    renderData(data) {

        let self = this;

        return (
            <div>
                <TableContainer
                    style={{ overflow: 'hidden' }}
                >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID hry</TableCell>
                                <TableCell>Stav</TableCell>
                                <TableCell>Cyklus</TableCell>
                                <TableCell>Aktuální datum</TableCell>
                                <TableCell align="right">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(
                                (game) => !isNil(game) && (
                                    <TableRow
                                        key={game.id}
                                        className="GameTable"
                                        hover
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {game.gameName}
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                onChange={(event) => {
                                                    self.changeGameStatus(event, game.id)
                                                }}
                                                value={game.state.status}
                                                disabled={game.state.status === "END_MONTH_PAUSED" || game.state.status === "ENDED"}
                                            >
                                                {Object.entries(GAME_STATUSES).map(([key, value]) => (
                                                    <MenuItem disabled={key === 'ENDED' || key === 'END_MONTH_PAUSED'} key={key} value={key}>{value}</MenuItem>
                                                )
                                                )}
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            {game.state.cycle}
                                        </TableCell>
                                        <TableCell>
                                            {game.currentTime}
                                        </TableCell>
                                        <TableCell align="right">
                                            <EditButton component={Link} to={`${adminDashboardUrl}${appUrl}/${game.id}`}>Upravit</EditButton>
                                            <DeleteButton warning warningMessage="Opravdu si přejete odstranit tuto hru?" onClick={() => {
                                                this.deleteGame(game.id);
                                            }}>
                                                Odstranit
                                            </DeleteButton>
                                        </TableCell>

                                    </TableRow>
                                ),
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default AdminGames;
