import React from 'react';
import {
    Backdrop, Typography, Dialog, DialogContent, Button, Box
} from '@material-ui/core';
import { Link } from 'react-router-dom';

/**
 * Informacni komponenta pro pozastavenou hru
 */
const PausedGameBackDrop = ({ open }) => (
    <Backdrop open={open}>
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Typography className="dots txt-al--cen" variant="h3">
                    Hra je zastavena
                </Typography>
                <Box textAlign="center" my={3}>
                    <Button variant="contained" color="primary" component={Link} to={'/games'}>Zpět na přehled her</Button>
                </Box>
            </DialogContent>
        </Dialog>
    </Backdrop>
);

export default PausedGameBackDrop;
