import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

export default class CreateGameEconomicsMaterialComponent extends Component {
    render() {
        return (
            <div>
                <Typography variant="h4">Materiál</Typography>
            </div>
        )
    }
}
