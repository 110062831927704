import React from 'react';
import { Grid, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

class HotelEquipmentQuality extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
    }
    componentDidUpdate() {
        this.data = this.props.data;
    }
    options0to100 = () => {
        let rows = [];
        for (let i = 0; i < 1; i += 0.01) {
            rows.push({ type: Math.round(i * 100) / 100, value: (i * 100).toFixed(2) + "%" });
        }
        return rows;
    }
    updateInput = (event) => {
        this.data[event.target.name] = event.target.value;
        this.props.updateQuality(this.data);
    }
    render() {
        return (
            <Grid container>
                <Grid item sm={12}>
                    <label className="form-label" style={{ fontWeight: "bold" }}>{this.props.label}</label>
                </Grid>
                <Grid item sm={12}>
                    <Grid container>
                        <Grid item sm={6}>
                            <label className="form-label">Investice do vybavení hotelu v % z tržeb:</label>
                        </Grid>
                        <Grid item sm={6}>
                            <Select
                                labelId="demo-simple-select-label"
                                name="invest"
                                value={this.props.data.invest}
                                onChange={this.updateInput}
                            >
                                {this.options0to100().map(item => <MenuItem key={item.type} value={item.type}>{item.value}</MenuItem>)}
                            </Select>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item sm={12}>
                    <Grid container>
                        <Grid item sm={6}>
                            <label className="form-label">Úroveň vybavení hotelu:</label>
                        </Grid>
                        <Grid item sm={6}>
                            <TextField
                                id="standard-basic"
                                name="EquipQuality"
                                value={this.props.data.EquipQuality}
                                disabled={true}
                                onChange={this.updateInput}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        );
    }
}
export default HotelEquipmentQuality;
