import React, { useState } from 'react';
import { Paper } from '@material-ui/core';

import AllUsersTable from '../../components/AllUsersTable';


/**
 * Komponenta/obrazovka s prehledem uzivatelu
 */
const ListOfUsers = () => {
    const [addNewPerson, setAddNewPerson] = useState(false);
    return (
        <div>
            <h1 className="adminPageTitle">Přehled uživatelů</h1>
            <Paper className="adminMainContent">
                <AllUsersTable
                    addNewPerson={addNewPerson}
                    setAddNewPerson={setAddNewPerson}
                />
            </Paper>
        </div>
    );
};

export default ListOfUsers;
