import React from 'react';
import { Box, Button, Grid, LinearProgress } from '@material-ui/core';
import WSForm from '../../../websocketService/WSForm';
import Typography from "@material-ui/core/Typography";

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.fileId = props.fileId
        this.state = { file: null, progress: 0, error: null, lastPartUploaded: 0, fileParts: [], fileHash: '' };
    }
    componentDidMount() {
        window.ws.addEventListener('message', this.websocketUpdate);
    }
    componentWillUnmount() {
        window.ws.removeEventListener('message', this.websocketUpdate);
    }

    websocketUpdate = (event) => {
        const data = JSON.parse(event.data);
        if (data.event === "FILE_UPLOADED") {
            this.setState({
                progress: 100,
            })
            this.props.fileUploaded(data.data)
        }
        if (data.event === "FILE_UPLOAD_STATUS") {
            if (data.status === "UPLOADED") {
                if (data.filePart + 1 < this.state.fileParts.length && this.state.lastPartUploaded === data.filePart) {
                    // a file part successfully uploaded
                    // partUploaded : (data.status === "UPLOADED"),
                    console.log('uploaded ' + Math.round(data.filePart / this.state.fileParts.length * 100) + '%');
                    console.log({ currentPart: data.filePart, totalCount: this.state.fileParts.length });
                    this.setState({
                        lastPartUploaded: data.filePart + 1,
                        progress: Math.round(data.filePart / this.state.fileParts.length * 100)
                    }, () => {
                        // upload next part
                        this.uploadPart(data.filePart + 1);
                    });
                }

            }
        }
        if (data.event === "FILE_UPLOAD_ERROR") {
            this.setState({
                error: data.data
            });
        }
    }

    getFileHashHex = async (fileContentsString) => {
        const uint8array = new TextEncoder().encode(fileContentsString); // UTF-8 encode to ArrayBuffer
        const digestArrayBuffer = await window.crypto.subtle.digest('SHA-256', uint8array);
        const digestHexStr = Array.prototype.map.call(new Uint8Array(digestArrayBuffer), x => ('00' + x.toString(16)).slice(-2)).join('');
        console.log('SHA-256 of the file:', digestHexStr)
        return digestHexStr;
    }


    chunkSubstr = (str, size) => {
        const numChunks = Math.ceil(str.length / size)
        const chunks = new Array(numChunks)

        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = str.substr(o, size)
        }

        return chunks
    }

    onChange = (event) => {
        if (event.target.type === "file") {
            let file = event.target.files[0]
            const reader = new FileReader();
            reader.onload = async (evt) => {
                const fileContents = evt.target.result
                // split file contents into parts (chunks) to be uploaded via WS messages
                const fileParts = this.chunkSubstr(fileContents, 100000)
                console.log("fileParts length", fileParts.length)
                this.setState({
                    fileName: file.name,
                    fileParts,
                    fileHash: await this.getFileHashHex(fileContents)
                });
            }
            reader.readAsText(file, "UTF-8");
        }
    }

    uploadPart = (partId, fileHash = null) => {
        var message = {
            event: "FILE_UPLOAD",
            data: {
                fileId: this.fileId,
                partId: partId,
                length: this.state.fileParts.length - 1,
                values: this.state.fileParts[partId],
                fileHash: fileHash, // file hash passed in the fist part (first FILE_UPLOAD message), server may reply with FILE_UPLOADED in case the same file is already uploaded
            }
        };
        window.ws.send(JSON.stringify(message));
    }

    upload = () => {
        this.setState({
            error: "",
            progress: 0,
            lastPartUploaded: 0,
        }, () => {
            this.uploadPart(0, this.state.fileHash);
        })
    }


    render() {

        let progress = ""
        if (this.state.progress !== 0) {
            progress = <LinearProgressWithLabel value={this.state.progress} />

        }

        var error = "";
        if (this.state.error != null) {
            error = <Grid item xs={6} style={{ color: "red" }}>{this.state.error}</Grid>
        }

        return (
            <div>
                <Box xs={12} my="1rem">
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Zvolit soubor
                        <input
                            name={this.props.name} onChange={this.onChange}
                            type="file"
                            hidden
                        />
                    </Button>
                    <label style={{ display: this.state.fileName ? 'inline-block' : 'none', margin: '0 10px' }}><strong>Vybráno:</strong> {this.state.fileName}</label>
                </Box>

                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.upload}
                        disabled={this.state.progress > 0}
                    >
                        Nahrát
                    </Button>
                </Box>
                <Box style={{ padding: 10 }}>
                    {progress}
                </Box>
                {error}
            </div>
        );
    }
}

export default FileUpload;
