export const appUrl = '/game';
export const adminDashboardUrl = '/admin';
export const allGamesUrl = '/games';
export const loginUrl = '/login';
export const landing = '/';

export const adminMonthly = '/monthly';
export const adminGraphs = '/graphs';
export const adminListOfUsers = '/list-of-users';
export const adminListOfGames = '/list-of-games';
export const settings = '/settings';

