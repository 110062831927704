import React from 'react';
import { Grid, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import WSForm from "../../../../../websocketService/WSForm";

class PropagationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
    }
    componentDidUpdate() {
        this.data = this.props.data;
    }
    updateInput = (event) => {
        this.data[event.target.name] = event.target.value;
        this.props.updateQuality(this.data);
    }
    render() {
        return (
            <Grid container>
                <Grid item sm={3}>
                    <label className="form-label" style={{ fontWeight: "bold" }}>{this.props.label}</label>
                </Grid>
                <Grid item sm={12}>
                    <Grid container>
                        <Grid item sm={6}>
                            <label className="form-label">Výdaje na propagaci:</label>
                        </Grid>
                        <Grid item sm={6}>
                            <TextField
                                id="standard-basic"
                                name="propagation"
                                value={this.props.data.propagation}
                                onChange={this.updateInput}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
export default PropagationComponent;
