import React from 'react';
import {
    Box,
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    Container,
    Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';
import EventIcon from '@material-ui/icons/Event';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { appUrl } from '../../utils/routesPath';
import WSServedComponent from '../../websocketService/WSServedComponent';
import { AuthContext } from '../../contexts/AuthProvider';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

import GAME_STATUSES from '../../constants/gameStatuses';
import hotelImg from '../../assets/images/hotel.svg';

/**
 * Komponenta/obrazovka s prehledem vsech her (pro hrace dostupnych her)
 */

class Games extends WSServedComponent {
    constructor(props, context) {
        super(props, context);
        this.event = 'GET_ALL_USER_GAMES';
        this.eventData = {};
        this.eventResponse = 'ALL_USER_GAMES_HERE';
        this.autoUpdatePeriod = 2000;
    }

    renderData(data) {
        return (
            <div>
                <Container maxWidth="lg" className="MinHeight height100 adminMainContent">
                    <h1 className="adminPageTitle">Moje hry</h1>
                    <Grid container spacing={2}>
                        {data.length > 0 ? data.map(
                            (game) => !isNil(game) && (
                                <Grid item xs={4} key={game.id}>
                                    <Card className="GameCard">
                                        <CardMedia image={hotelImg} className="GameCardImage" />
                                        <CardContent>
                                            <Box className="GameCardStatusWrap GameCardRow" component="p">
                                                <span className={'GameCardStatus game_status_' + game.state.status} />
                                                <span className="GameCardStatusText">{GAME_STATUSES[game.state.status]}</span>
                                            </Box>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {game.gameName}
                                            </Typography>
                                            <Box className="GameCardRow" component="p">
                                                <DateRangeIcon className="GameCardDateIcon" color="primary" />{moment(game.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY')} - {moment(game.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                                            </Box>
                                            <Box className="GameCardRow" component="p">
                                                <EventIcon className="GameCardDateIcon" color="primary" />{game.currentTime}
                                            </Box>
                                        </CardContent>
                                        <CardActions className="GameCardFooter">
                                            <Button variant="contained" color="primary" component={Link} to={`${appUrl}/${game.id}`}>Vstoupit</Button>
                                        </CardActions>

                                    </Card>
                                </Grid>
                            ),
                        ) : (
                            <Grid
                                item
                                xs={12}
                                >
                                <Box textAlign="center" my={5}>
                                    <SearchIcon color="primary" style={{fontSize: '4rem'}}/>
                                    <Typography variant="subtitle2">Zatím vám nebyla přiřazena žádná hra.</Typography>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </div>
        );
    }
}
Games.contextType = AuthContext;
export default Games;
