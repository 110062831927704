import React from 'react';
import {
    MenuItem, Select, TableCell, TableRow, TextField, Button, Grid,
} from '@material-ui/core';
import WSForm from '../../websocketService/WSForm';
import SelectUser from "./SelectUser";
import DeleteButton from '../../components/Buttons/DeleteButton';
import SaveIcon from '@material-ui/icons/Save';
class HotelTableRow extends WSForm {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.ws.addEventListener('message', this.websocketUpdate);
    }
    componentWillUnmount() {
        window.ws.removeEventListener('message', this.websocketUpdate);
    }
    websocketUpdate = (event) => {
        const data = JSON.parse(event.data);

        if (data.event === "HOTEL_CREATED") {
            let message = {
                event: "JOIN_GAME",
                data: {
                    playerId: this.getFormValue("player"),
                    gameId: this.props.gameId
                }
            };

            window.ws.send(JSON.stringify(message));
        }

        if (data.event === "HOTEL_UPDATED"
            || data.event === "HOTEL_CREATED"
            || data.event === "HOTEL_DELETED") {
            window.location.reload();
        }
    }

    deleteRow = () => {
        let message = {
            event: "DELETE_HOTEL",
            data: {
                id: this.props.data.id
            }
        }
        window.ws.send(JSON.stringify(message));
    }

    saveRow = () => {

        let message = "";
        let hotel = {
            name: this.getFormValue("name"),
            player: this.getFormValue("player"),
            capacity: this.getFormValue("capacity"),
            brandLevel: this.getFormValue("brandLevel"),
            exclusivePercentage: this.getFormValue("exclusivePercentage"),
            gameId: this.props.gameId
        };

        if (this.props.data.id === "") {
            message = {
                event: "CREATE_HOTEL",
                data: hotel
            };
        } else {
            hotel.id = this.props.data.id;
            message = {
                event: "UPDATE_HOTEL",
                data: hotel
            };
        }
        window.ws.send(JSON.stringify(message));
    }

    render() {
        return (
            <TableRow key={this.props.data.id}>
                <TableCell component="th" scope="row">
                    {this.props.data.name}
                </TableCell>
                <TableCell>
                    <TextField name="name" value={this.getFormValue('name') || ""} onChange={this.onChange} />
                </TableCell>
                <TableCell><SelectUser name={"player"} value={this.getFormValue('player')} onChange={this.onChange} /></TableCell>
                <TableCell>
                    <TextField name="capacity"  type="number" value={this.getFormValue('capacity')} onChange={this.onChange} />
                </TableCell>
                <TableCell>
                    <Select
                        labelId="demo-simple-select-label"
                        name="exclusivePercentage"
                        onChange={this.onChange}
                        value={this.getFormValue('exclusivePercentage')}
                    >
                        <MenuItem value={0.1}>0.1</MenuItem>
                        <MenuItem value={0.2}>0.2</MenuItem>
                        <MenuItem value={0.3}>0.3</MenuItem>
                        <MenuItem value={0.4}>0.4</MenuItem>
                        <MenuItem value={0.5}>0.5</MenuItem>
                        <MenuItem value={0.6}>0.6</MenuItem>
                        <MenuItem value={0.7}>0.7</MenuItem>
                        <MenuItem value={0.8}>0.8</MenuItem>
                        <MenuItem value={0.9}>0.9</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                    </Select>
                </TableCell>
                <TableCell>
                    <Select
                        labelId="demo-simple-select-label"
                        name="brandLevel"
                        onChange={this.onChange}
                        value={this.getFormValue('brandLevel')}
                    >
                        <MenuItem value={0.1}>0.1</MenuItem>
                        <MenuItem value={0.2}>0.2</MenuItem>
                        <MenuItem value={0.3}>0.3</MenuItem>
                        <MenuItem value={0.4}>0.4</MenuItem>
                        <MenuItem value={0.5}>0.5</MenuItem>
                        <MenuItem value={0.6}>0.6</MenuItem>
                        <MenuItem value={0.7}>0.7</MenuItem>
                        <MenuItem value={0.8}>0.8</MenuItem>
                        <MenuItem value={0.9}>0.9</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                    </Select>
                </TableCell>
                <TableCell align="right">
                    <Button startIcon={<SaveIcon />} variant="contained" color="primary" onClick={this.saveRow}>
                        Uložit
                    </Button>
                    <DeleteButton warning warningMessage="Opravdu chcete tento hotel odstranit?" onClick={this.deleteRow}>
                        Odstranit
                    </DeleteButton>
                </TableCell>
            </TableRow>
        );
    }
}
export default HotelTableRow;
