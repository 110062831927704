import React from 'react';
import { Box, Grid } from '@material-ui/core';
import WSServedComponent from '../../../../../websocketService/WSServedComponent';
import ServiceItem from '../../../../../components/Form/ServiceItem';

class ServicesSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        /*        const data = this.props.services;
               const half = Math.ceil(data.length / 2);
               const firstHalf = data.splice(0, half);
               const secondHalf = data.splice(-half); */
        const half = Math.ceil(this.props.services.length / 2);
        return (
            <Box>
                <Box className="ServicesItems">
                    <Grid className="ServiceItem" container style={{ borderRight: '2px solid #ccc', padding: '20px 0' }}>
                        <Grid item sm={6}>
                            <label className="form-label">Název služby</label>
                        </Grid>
                        <Grid item sm={3}>
                            <label className="form-label">Nabízet</label>
                        </Grid>
                        <Grid item sm={3}>
                            <label className="form-label">Cena</label>
                        </Grid>
                    </Grid>
                    <Grid className="ServiceItem" container style={{ padding: '20px 0' }}>
                        <Grid item sm={6}>
                            <label className="form-label">Název služby</label>
                        </Grid>
                        <Grid item sm={3}>
                            <label className="form-label">Nabízet</label>
                        </Grid>
                        <Grid item sm={3}>
                            <label className="form-label">Cena</label>
                        </Grid>
                    </Grid>
                </Box>
                <Box className='ServicesItems'>
                    {this.props.services.map((service, index) => <ServiceItem key={index} style={{ borderRight: index > half - 1 ? 'none' : '2px solid #ccc' }} data={service} updateService={this.props.updateService} />)}
                </Box>
            </Box>
        );
    }
}
export default ServicesSettings;
