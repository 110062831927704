import { Box, Tabs, Tab } from '@material-ui/core'
import React, { Component } from 'react'
import MarketRivals from '../MarketRivals';
import MarketEvents from '../MarketEvents';

export default class MarketResearch extends Component {
    constructor(props) {
        super(props);
        this.state = {selectedTab: 1}
    }
    changeTab = (event, newValue) => {
        this.setState({selectedTab: newValue});
    }
    render() {
        let content = ""
        switch (this.state.selectedTab) {
            case 1:
                content = <MarketRivals />
                break;
            case 2:
                content = <MarketEvents/>
                break;
        }
        return (
            <Box className="height100">
                <Box className="stickyHeader" alignItems="flex-end">
                    <Tabs
                        value={this.state.selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.changeTab}
                    >
                        <Tab value={1} label="Konkurence" />
                        <Tab value={2} label="Eventy" />
                    </Tabs>
                </Box>
                <Box className="adminMainContent" style={{ minHeight: 'calc(100% - 100px)' }}>
                    {content}
                </Box>
            </Box>
        )
    }
}
