import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withLastLocation } from 'react-router-last-location';
import { CircularProgress, Paper } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import Alert from '../../components/Alert';
import bgImage from '../../assets/images/login_bg.svg';
import { AuthContext } from '../../contexts/AuthProvider';
/**
 * Definice stylu
 */
const useStyles = (theme) => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

/**
 * Komponenty/obrazovka prihlaseni
 */

class SignIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = { name: '', password: '', error: false };
        this.setName = this.setName.bind(this);
        this.setPassword = this.setPassword.bind(this);

    }

    setName(name) {
        this.setState({ name: name, password: this.state.password, error: false });
    }

    setPassword(password) {
        this.setState({ name: this.state.name, password: password, error: false });
    }

    handleSubmitClick = (e) => {
        this.context.login(this.state.name, this.state.password, this.error)
    }

    error = () => {
        this.setState({ name: this.state.name, password: this.state.password, error: true });
    }

    render() {
        const { classes } = this.props;
        const { name, password, error } = this.state;
        const msg = 'Přihlašovací údaje jsou neprávné, zkuste to znovu.';
        return (
            <div
                style={{ backgroundImage: `url(${bgImage})` }}
                className="Login"
            >
                <CssBaseline />
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className="loginInner"
                >
                    <Paper className={`${classes.paper} form`}>
                        <Typography component="h1" variant="h4" className="loginAppName">
                            HOTELOVÝ SIMULÁTOR
                        </Typography>
                        <Typography component="h1" variant="h5">
                            Příhlášení do aplikace
                        </Typography>
                        {error && <Alert text={msg} />}
                        <form
                            className={classes.form}
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            noValidate
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Uživatelské jméno"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                error={error}
                                onChange={(e) => this.setName(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Heslo"
                                type="password"
                                id="password"
                                error={error}
                                autoComplete="current-password"
                                onChange={(e) => this.setPassword(e.target.value)}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={this.handleSubmitClick}
                            >
                                {this.state.loadingUser ? (
                                    <CircularProgress style={{ color: 'white' }} />
                                ) : (
                                    'Přihlásit se'
                                )}
                            </Button>
                        </form>
                    </Paper>
                </Grid>
            </div>
        );
    }
}
SignIn.contextType = AuthContext;
export default withStyles(useStyles)(withLastLocation(SignIn));
