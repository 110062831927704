import React from 'react';
import { Grid, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

class PersonSalaryInput extends React.Component {
    constructor(props) {
        super(props);
        this.data = {
            count: this.props.data.count,
            salary: this.props.data.salary
        };
    }
    componentDidUpdate() {
        this.data = {
            count: this.props.data.count,
            salary: this.props.data.salary
        };
    }
    options0to100 = () => {
        let rows = [];
        for (let i = 0; i < 100; i++) {
            rows.push(i);
        }
        return rows;
    }
    updateInput = (event) => {
        this.data[event.target.name] = event.target.value;
        this.props.updateWorker(this.props.data.position, this.props.data.type, this.data);
    }
    render() {
        return (
            <Grid container>
                <Grid item sm={3}>
                    <label className="form-label">{this.props.label}</label>
                </Grid>
                <Grid item sm={5}>
                    <Select
                        labelId="demo-simple-select-label"
                        name="count"
                        value={this.props.data.count}
                        onChange={this.updateInput}
                    >
                        {this.options0to100().map((number) => <MenuItem key={number} value={number}>{number}</MenuItem>)}
                    </Select>
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        id="standard-basic"
                        name="salary"
                        value={this.props.data.salary}
                        disabled={true}
                        onChange={this.updateInput}
                    />
                </Grid>
            </Grid>
        );
    }
}
export default PersonSalaryInput;
