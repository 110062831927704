import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PersonSalaryInput from '../Form/PersonSalaryInput';

class WorkersSettingsComponent extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        const elements = [];

        if (this.props.data.hasOwnProperty("junior")) {
            elements.push(
                <Grid container item sm={12} className="form-group" key={this.props.id + '_junior'}>
                    <PersonSalaryInput
                        label="Junior"
                        data={{
                            type: 'junior', position: this.props.id, salary: this.props.data.junior.salary, count: this.props.data.junior.count,
                        }}
                        updateWorker={this.props.updateWorker}
                    />
                </Grid>,
            );
        }

        if (this.props.data.hasOwnProperty("senior")) {
            elements.push(
                <Grid container item sm={12} className="form-group" key={this.props.id + '_senior'}>
                    <PersonSalaryInput
                        label="Senior"
                        data={{
                            type: 'senior', position: this.props.id, salary: this.props.data.senior.salary, count: this.props.data.senior.count,
                        }}
                        updateWorker={this.props.updateWorker}
                    />
                </Grid>,
            );
        }

        return (
            <Grid container item sm={12} className="form-group">
                <form className="form-example">
                    <Typography
                        variant="h5"
                        color="textSecondary"
                        className="txt-al--cen form-heading"
                    >
                        {this.props.label}
                    </Typography>
                    {elements}
                </form>
            </Grid>
        );
    }
}

export default WorkersSettingsComponent;
