import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route } from 'react-router';
import AdminNavigation from '../../containers/AdminNavigation';
import CreateGame from './CreateGame';
import ListOfGames from './ListOfGames';
import ListOfUsers from './ListOfUsers';
import { adminDashboardUrl, adminListOfGames, adminListOfUsers, appUrl } from '../../utils/routesPath';
import WSServedComponent from "../../websocketService/WSServedComponent";
import AdminRoute from '../../routes/AdminRoute';

/**
 * Komponenta/obrazovka administrace
 */
class Admin extends WSServedComponent {
    constructor() {
        super();
        this.event = "GET_ALL_GAMES";
        this.eventResponse = 'GAMES_HERE';
    }
    componentDidMount() {
        super.componentDidMount();
        this.updateCustomStateProperty("create-game-step", 0)
    }
    setActiveStep = (val) => {
        this.updateCustomStateProperty("create-game-step", val);
    }

    initializeGameWithSetting = (settings) => {
        this.sendData(
            {
                event: 'INITIALIZE_GAME',
                data: {
                    setting: {
                        ...settings,
                    },
                },
            }
        )
    };

    render() {
        return (
            <div className="MainContent">
                <CssBaseline />

                <AdminNavigation />
                <div className="MainContentRight">
                    <AdminRoute path={`${adminDashboardUrl}`} >
                        <CreateGame
                            activeStep={this.getCustomStateProperty("create-game-step")}
                            setActiveStep={this.setActiveStep}
                        />
                    </AdminRoute>

                    <AdminRoute path={`${adminDashboardUrl}${appUrl}/:id`}>
                        <CreateGame />
                    </AdminRoute>

                    <AdminRoute path={`/admin/games`} >
                        <ListOfGames />
                    </AdminRoute>

                    <AdminRoute path={`/admin${adminListOfUsers}`}>
                        <ListOfUsers />
                    </AdminRoute>

                </div>
            </div>
        );
    }
}

export default Admin;
