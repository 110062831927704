import { keyMirror } from 'ramda-extension';

export const modalWindowsKeys = keyMirror({
    QUALITY_IN_ACCOMMODATION_SECTION: null,
    QUALITY_IN_FAB_SECTION: null,
    RECEPTION_WORKERS: null,
    MESS_WORKERS: null,
    FAB_WORKERS: null,
    QUALITY_OF_ROOMS: null,
    PROMOTION_EXPENDITURE: null,
    END_OF_ROUND: null,
});
export const modalActions = keyMirror({
    SHOW_MODAL: null,
    HIDE_MODAL: null,
});
