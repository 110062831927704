import { Field } from 'redux-form';
import { Grid } from '@material-ui/core';
import React from 'react';
import { options0to100 } from '../../../Modals/utils/reduxForm';
const LabelAndSelect = ({
    label,
    labelGrid,
    selectGrid,
    disable,
    fieldName,
    options,
}) => {
    return (
        <Grid container justify={'center'} sm={12} item className={'form-group'}>
            <Grid container justify={'flex-end'} sm={labelGrid}>
                <label className={'form-label'} style={{ width: 'auto' }}>
                    {label}
                </label>
            </Grid>
            <Grid
                container
                justify={'flex-start'}
                sm={selectGrid}
                item
                className={'form-padd'}
            >
                <Field
                    name={fieldName}
                    component={'select'}
                    className={'form-control'}
                    placeholder="Count"
                    disabled={disable}
                >
                    {options() || options0to100()}
                </Field>
            </Grid>
        </Grid>
    );
};

export default LabelAndSelect;
