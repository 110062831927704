import React, { Component } from 'react'
import ChartsWrap from './ChartsWrap';
import {
    Line,
    XAxis,
    YAxis
} from 'recharts';
export default class ExpensesAndGainsChart extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        console.log(this.props.data);
        return (
            <ChartsWrap data={this.props.data}>
                <XAxis dataKey="name" interval={0} scale="band" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" unit="%" domain={[0,100]}/>

                <Line name="OCC" yAxisId="right" type="monotone" dataKey="OCC" unit="%" stroke="#8bc34a" />
                <Line name="ADR" yAxisId="left" type="monotone" dataKey="ADR" stroke="#03a9f4" />
                <Line name="RevPAR" yAxisId="left" type="monotone" dataKey="RevPAR" stroke="#ff5722" />
                <Line name="TRevPAR" yAxisId="left" type="monotone" dataKey="TRevPAR" stroke="#ffc107" />
                <Line name="GOPPAR" yAxisId="left" type="monotone" dataKey="GOPPAR" stroke="#e91e63" />
            </ChartsWrap>
        )
    }
}
