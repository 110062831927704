import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Label } from '@material-ui/icons';
import { Checkbox, TextField } from '@material-ui/core';

class ServiceItem extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
    }
    componentDidUpdate() {
        this.data = this.props.data;
    }
    updateInput = (event) => {
        if (event.target.type === "checkbox") {
            this.data[event.target.name] = event.target.checked;
        } else {
            this.data[event.target.name] = event.target.value;
        }
        this.props.updateService(this.data.id, this.data)
    }
    render() {
        return (
            <Grid container className="ServiceItem" style={this.props.style}>
                <Grid item sm={7}>
                    <label className="form-label">{this.props.data.name}</label>
                </Grid>
                <Grid item sm={2}>
                    <Checkbox
                        checked={this.props.data.enabled}
                        onChange={this.updateInput}
                        color="primary"
                        name="enabled"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </Grid>
                <Grid item sm={2}>
                    <TextField disabled={!this.props.data.enabled} value={this.props.data.price} name="price" onChange={this.updateInput} />
                </Grid>
                <Grid item sm={1}>
                    €
                </Grid>
            </Grid>
        );
    }
}
export default ServiceItem;
