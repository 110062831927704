const monthDays = () => {
    let array = [];
    let i;
    for (i = 1; i <= 31; i++) {
        array = [...array, i];
    }
    return array;
};
export const rowHeadersAdvanceSales = [
    'Advance (non-ref)',
//  'Release pro advance', -asi k nicemu? nechodi pro to data
];
export const rowHeadersSales = [
    'Standardní pokoj - B.A.R',
    'Pokoj vyšší kategorie',
    'Provize OTA [%]',
    'Sleva pro firemní klientelu [%]',
    'Sleva pro firemni klientelu "s akcí" [%]',
    'Provize/sleva CK individuál [%]',
    'Provize/sleva CK skupinové [%]',
    'Gratuita (x.osoba)',
];
export const rowHeadersRoomTotal = [
    'Celkem pokojů',
    'Celkem prodáno',
    'Celkem zbývá',
];
export const rowHeadersRoomSales = [
    'Prodáno standard',
    'Zbývá k prodeji stanadard',
    'Prodáno vyšší kategorie',
    'Zbývá k prodeji vyšší kategorie',
];
export const rowHeadersOnlineSales = [
    'K prodeji online standard',
    'K prodeji online vyšší kategorie',
];
const headersColumn = monthDays();

export default headersColumn;
