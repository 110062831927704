import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import yellow from '@material-ui/core/colors/yellow';

const useStyles = makeStyles((theme) => ({
    button: {
        textDecoration: 'none !important',
        margin: theme.spacing(1),
        backgroundColor: yellow[800],
        color: '#FFFFFF',
        "&:hover": {
            color: '#FFFFFF !important',
            backgroundColor: yellow[900],
        }
    }
}));
export default function EditButton(props) {
    const classes = useStyles();
    let {onclick, ...passDownProps} = props;
    return (
        <Button
            variant="contained"
            startIcon={<Edit />}
            className={classes.button}
            {...passDownProps}
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}
        >
            {props.children}
        </Button>
    );
}
EditButton.defaultProps = {
    onClick: () => { return false; }
}