import React from 'react';
import { indexOf } from 'ramda';
import { MenuItem } from '@material-ui/core';
import moment from 'moment';
const months = [
    'Leden',
    'Únor',
    'Březen',
    'Duben',
    'Květen',
    'Červen',
    'Červenec',
    'Srpen',
    'Září',
    'Říjen',
    'Listopad',
    'Prosinec',
    'Leden',
];

export const getMonths = () => months;

export const monthsMap = () => months.map((month, index) => ({ type: month, value: index + 1 }));

export const monthsInOption = () => monthsMap().map(({ type, value }) => <option value={value}>{type}</option>);

export const monthsInOptionMenuItem = () => monthsMap().map(({ type, value }) => (
    <MenuItem value={value}>{type}</MenuItem>
));

const thirthy = [4, 6, 9, 11];
const thirthyOne = [1, 3, 5, 7, 8, 10, 12, 13];

const monthDays = (days) => {
    let array = [];
    let i;
    for (i = 1; i <= days; i++) {
        array = [...array, i];
    }
    return array;
};

export const daysInMonth = (monthId) => {

    const daysInMonthCount = moment(monthId, 'MM. YYYY').daysInMonth()
    if(daysInMonthCount) {
        return monthDays(daysInMonthCount)
    } 
    return [];
};

export const daysInOptions = (monthId) => daysInMonth(monthId).map((month) => (
    <option value={month}>{month}</option>
));
