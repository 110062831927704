import React from 'react';
import { reduxForm } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { validateRequired } from '../../../../Modals/utils/validations';
import LabelAndCheckBox from './LabelAndCheckBox';

const CateringServiceProvided = ({ disable }) => (
    <Grid container item sm={12} className={'form-group'}>
        <form className={'form-example'}>
            <Typography
                variant={'h5'}
                color="textSecondary"
                className={'txt-al--cen form-heading'}
            >
                Poskytované stravovací služby
            </Typography>
            <LabelAndCheckBox
                label={'Snídaně'}
                fieldName={'juniorSelect'}
                disable={disable}
            />
            <LabelAndCheckBox
                label={'Večeře (skupinové)'}
                fieldName={'juniorSelect'}
                disable={disable}
            />
            <LabelAndCheckBox
                label={'Večeře (á la carte)'}
                fieldName={'juniorSelect'}
                disable={disable}
            />
            <LabelAndCheckBox
                label={'Lobby car'}
                fieldName={'juniorSelect'}
                disable={disable}
            />
        </form>
    </Grid>
);
export default reduxForm({
    form: 'MessRoomsWorkers',
    validate: validateRequired(),
})(CateringServiceProvided);
