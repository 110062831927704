import React, { Component } from 'react'
import {
    Line,
    Bar,
    XAxis,
    YAxis
} from 'recharts';
import ChartsWrap from './ChartsWrap';
export default class ExpensesAndGainsChart extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        console.log(this.props.data);
        return (
            <ChartsWrap data={this.props.data}>
                <XAxis dataKey="name" interval={0} scale="band" />
                <YAxis />
                <Bar name="Příjmy" dataKey="totalGains" barSize={20} fill="#2E7D32" />
                <Bar name="Výdaje" dataKey="totalExpenses" barSize={20} fill="#B71C1C" />
                <Line name="Kumulativní příjmy" type="monotone" dataKey="cumulativeGains" stroke="#2E7D32" />
                <Line name="Kumulativní výdaje" type="monotone" dataKey="cumulativeExpenses" stroke="#B71C1C" />
            </ChartsWrap>
        )
    }
}
