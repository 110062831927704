import { Box, Grid, Paper, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { TimeContext } from '../../contexts/TimeProvider';
import WSServedComponent from '../../websocketService/WSServedComponent'

export default class MarketServices extends WSServedComponent {
    constructor(props, context) {
        super(props);
        this.state = { ...this.state };
        this.event = 'GET_COMP_SERVICES';
        this.eventResponse = 'COMP_SERVICES_HERE';
        this.eventData = {
            month: context.time.month
        };
    }
    renderData(data) {
        console.log(data);
        return (
            <Paper id="MarketServices">
                {Object.values(data).map((hotel, h_index) =>
                    <Box className="MarketServicesHotel" key={h_index}>
                        <Typography key={h_index} variant="subtitle1" className="MarketServicesHotelName">{hotel.name}</Typography>

                        {hotel.services.length < 1 ?
                         (<Grid container className="MarketService">Nenabízí žádné služby.</Grid>) : 
                         (hotel.services.map((service, s_index) =>
                            <Grid container key={h_index + '_' + s_index} className="MarketService">
                                <Grid item sm={8} className="MarketServiceName">
                                    {service.name}
                                </Grid>
                                <Grid item sm={4} className="MarketServicePrice">
                                    {service.price}
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                )}
            </Paper>
        )
    }
}
MarketServices.contextType = TimeContext;