import {
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Paper,
    FormControl,
    Input,
    InputAdornment,
    Button,
    Modal,
} from '@material-ui/core';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import AddNewUserForm from "../../pages/Admin/AddNewUserForm";
import WSServedComponent from "../../websocketService/WSServedComponent";
import DeleteButton from '../Buttons/DeleteButton';

/**
 * Komponenta/obrazovka s prehledem vsech uzivatelu (administrace)
 */
class AllUsersTable extends WSServedComponent {


    constructor(props, context) {
        super(props, context);
        this.state = { allUsers: [], addNew: false };
        this.event = 'USER_LIST';
        this.eventResponse = 'USER_LIST';
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        value = value.toLowerCase();
        let usersToShow = [];
        if (value.length > 2) {
            usersToShow = this.state.allUsers.filter((user) => (user.name.toLowerCase().includes(value) || user.email.toLowerCase().includes(value)));
        } else {
            usersToShow = this.state.allUsers;
        }
        this.setState({ usersToShow });
    }

    websocketDidUpdate() {
        //Zkopirovani vsech useru, aby pri vymazani vyhledavaciho pole sli zase vsichni zobrazit...
        this.setState({ allUsers: this.state.data, usersToShow: this.state.data });
    }

    openAddNewUser = () => {
        this.setState({ addNew: true });
    }

    closeAddNewUser = () => {
        this.setState({ addNew: false });
    }

    deleteUser = (userId) => {
        let message = {
            event: "DELETE_USER",
            data: {
                id: userId
            }
        }
        this.sendData(message)
    }

    renderData(data) {
        return (
            <TableContainer style={{ overflow: 'hidden' }}>
                <Grid container justify="flex-start" alignItems="center">
                    <Grid item xs={10} style={{ padding: '20px' }}>
                        <FormControl style={{ minWidth: 300 }}>
                            <Input
                                onChange={(e) => this.onChange(e.target.value)}
                                id="input-with-icon-adornment"
                                startAdornment={(
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <Button
                            className="Button"
                            variant="contained" color="primary"
                            onClick={this.openAddNewUser}
                        >
                            Přidat uživatele
                        </Button>
                        <Modal
                            open={this.state.addNew}
                            style={{ zIndex: 1400 }}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <Paper style={{
                                top: `${50}%`,
                                left: `${50}%`,
                                width: '400px',
                                position: 'absolute',
                                padding: '20px',
                                transform: `translate(-${50}%, -${50}%)`,
                            }}
                            >
                                <AddNewUserForm closeModal={this.closeAddNewUser} />
                            </Paper>
                        </Modal>
                    </Grid>
                </Grid>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Jméno</TableCell>
                            <TableCell>Věk</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.usersToShow && this.state.usersToShow.map(user =>
                            <TableRow
                                key={user.id}
                                className="GameTable"
                                hover
                            >
                                <TableCell>
                                    {user.id}
                                </TableCell>
                                <TableCell>
                                    {user.name}
                                </TableCell>
                                <TableCell>
                                    {user.age}
                                </TableCell>
                                <TableCell>
                                    {user.email}
                                </TableCell>
                                <TableCell>
                                    {user.role}
                                </TableCell>
                                <TableCell align="right">
                                    <DeleteButton
                                        warning
                                        warningMessage="Opravdu si přejete tohoto uživatele odstranit?"
                                        onClick={() => this.deleteUser(user.id)}>
                                        Odstranit
                                    </DeleteButton>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default AllUsersTable;
