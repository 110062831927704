import React from 'react'
import { Grid, Typography } from "@material-ui/core";
import WorkersSettingsComponent from "../WorkerSettingsComponent";
import WSServedComponent from "../../websocketService/WSServedComponent";
import HousingResourceQuality from "../../containers/Forms/App/Forms/HousingResourceQuality";
import FABResourceQuality from "../../containers/Forms/App/Forms/FABResourceQuality";
import PropagationComponent from "../../containers/Forms/App/Forms/PropagationComponent";
import HotelEquipmentQuality from "../../containers/Forms/App/Forms/HotelEquipmentQuality";

class QualitySettings extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <Grid container item sm={12}>
                <Grid item sm={12}>
                    <h3 style={{ width: '100%', textAlign: 'center' }}>Kvalita surovin</h3>
                </Grid>
                <Grid item sm={6}>
                    <HousingResourceQuality id="housingQuality" data={this.props.quality.housingQuality} label="Kvalita surovin ubytovacího úseku" updateQuality={this.props.updateQuality} />
                </Grid>
                <Grid item sm={6}>
                    <FABResourceQuality id="FABQuality" data={this.props.quality.FABQuality} label="Kvalita surovin F&B úseku" updateQuality={this.props.updateQuality} />
                </Grid>
                <Grid item sm={6}>
                    <HotelEquipmentQuality id="hotelEquipmentQuality" data={this.props.quality.hotelEquipmentQuality} label="Kvalita vybavení hotelu" updateQuality={this.props.updateQuality} />
                </Grid>
                <Grid item sm={6}>
                    <PropagationComponent id="propagation" data={this.props.quality.propagation} label="Propagace" updateQuality={this.props.updateQuality} />
                </Grid>
            </Grid>
        );
    }
}
export default QualitySettings;
