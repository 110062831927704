import React from 'react';
import { Box, Tab, Tabs, Typography, Paper, AppBar } from '@material-ui/core';
import CreateGameEconomicsPersonalComponent from "./CreateGameEconomicsPersonalComponent";
import CreateGameEconomicsMaterialComponent from "./CreateGameEconomicsMaterialComponent";
import CreateGameEconomicsServicesComponent from "./CreateGameEconomicsServicesComponent";
import { withRouter } from "react-router";

class CreateGameEconomicsComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = { ...this.state, activeTab: 1 };
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ activeTab: newValue })
    }

    render() {

        var content = <div>none</div>
        switch (this.state.activeTab) {
            case 1:
                content = <CreateGameEconomicsPersonalComponent />;
                break;
            case 2:
                content = <CreateGameEconomicsMaterialComponent />;
                break;
            case 3:
                content = <CreateGameEconomicsServicesComponent />;
                break;
        }


        return (
            <Box>
                <Typography variant="h4">Nastavení ekonomiky</Typography>
                <Paper className="adminPaperBox">
                    <AppBar position="static" elevation={0}>
                        <Tabs
                            value={this.state.activeTab}
                            onChange={this.handleChangeTab}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#FFFFFF"
                                }
                            }}>
                            <Tab value={1} label="Personál" />
                            <Tab value={2} label="Materiál" />
                            <Tab value={3} label="Realizace služeb" />
                        </Tabs>
                    </AppBar>
                    <Box className="adminMainContent">
                        {React.cloneElement(content)}
                    </Box>
                </Paper>
            </Box>
        );

    }
}
export default CreateGameEconomicsComponent;
