import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import React, { Component } from 'react'
import ExpensesAndGainsComponent from './ExpensesAndGainsComponent';
import OTBComponent from './OTBComponent';
import PerformanceComponent from './PerformanceComponent';
export default class UserReports extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { ...this.state, activeTab: 1 };
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ activeTab: newValue })
    }

    render() {


        var content = <div>none</div>
        switch (this.state.activeTab) {
            case 1:
                content = <ExpensesAndGainsComponent />;
                break;
            case 2:
                content = <OTBComponent />;
                break;
            case 3:
                content = <PerformanceComponent />;
                break;
        }



        return (
            <Box className="height100">
                <Box className="stickyHeader" alignItems="flex-end">
                    <Tabs
                        value={this.state.activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChangeTab}
                        aria-label="disabled tabs example"
                    >
                        <Tab value={1} label="Náklady a výnosy" />
                        <Tab value={2} label="OTB" />
                        <Tab value={3} label="Výkon" />
                    </Tabs>
                </Box>
                <Box className="adminMainContent">
                        {content}
                </Box>
            </Box>

        );
    }
}
