import React, { Component } from 'react'
import {
    ComposedChart,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { withTheme } from '@material-ui/core/styles';

class ChartsWrap extends Component {
    constructor(props) {
        super(props);
        console.log(props);
    }
    render() {
        return (
            <div style={{ height: '55vh' }}>
                <ResponsiveContainer>
                    <ComposedChart
                        width={750}
                        height={400}
                        data={this.props.data}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <Tooltip
                            labelStyle={{ fontWeight: 700, backgroundColor: this.props.theme.palette.primary.main, color: this.props.theme.palette.primary.contrastText, padding: '5px 10px', marginBottom: 5}}
                            contentStyle={{ border: 'none', padding: '0 0 5px 0' }}
                            itemStyle={{padding: '5px 10px'}}
                            wrapperStyle={{ boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12)', borderRadius: 4, overflow: 'hidden', border: 'none', padding: 0 }} />
                        <Legend />
                        <CartesianGrid stroke="#f5f5f5" />
                        {this.props.children}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        )
    }
}
export default withTheme(ChartsWrap);