import React from 'react';

export function options0to100() {
    let rows = [];
    for (let i = 0; i < 100; i++) {
        rows.push(<option key={i}>{i}</option>);
    }
    return rows;
}

export function options0to100InPerc() {
    let rows = [];
    for (let i = 0; i < 100; i++) {
        rows.push(<option key={i}>{i}%</option>);
    }
    return rows;
}
