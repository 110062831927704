import { Typography } from '@material-ui/core'
import React, { Component } from 'react'

export default class CreateGameEconomicsPersonalComponent extends Component {
    render() {
        return (
            <div>
                <Typography variant="h4">Personál</Typography>
            </div>
        )
    }
}
