import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { validateRequired } from '../../../../Modals/utils/validations';
import { options0to100 } from '../../../../Modals/utils/reduxForm';

const ReceptionWorkersForm = ({ disable }) => (
    <Grid container item sm={12} className={'form-group'}>
        <form className={'form-example'}>
            <Typography
                variant={'h5'}
                color="textSecondary"
                className={'txt-al--cen form-heading'}
            >
                Pracovníci recepce
            </Typography>
            <Grid container item sm={12} className={'form-group'}>
                <Grid item sm={2}>
                    <label className={'form-label'}>{'Junior'}</label>
                </Grid>
                <Grid item sm={3} className={'form-padd'}>
                    <Field
                        name={'juniorSelect'}
                        component={'select'}
                        className={'form-control'}
                        placeholder="Count"
                        disabled={disable}
                    >
                        {options0to100()}
                    </Field>
                </Grid>
                <Grid item sm={7} className={'form-padd'}>
                    <Field
                        name={'juniorSalary'}
                        component="input"
                        type="text"
                        className={'form-control'}
                        placeholder="Junior salary"
                        disabled={disable}
                    />
                </Grid>
            </Grid>
            <Grid container item sm={12} className={'form-group'}>
                <Grid item sm={2}>
                    <label className={'form-label'}>{'Senior'}</label>
                </Grid>
                <Grid item sm={3} className={'form-padd'}>
                    <Field
                        name={'juniorSelect'}
                        component={'select'}
                        className={'form-control'}
                        placeholder="Count"
                        disabled={disable}
                    >
                        {options0to100()}
                    </Field>
                </Grid>
                <Grid item sm={7} className={'form-padd'}>
                    <Field
                        name={'seniorSalary'}
                        component="input"
                        type="text"
                        className={'form-control'}
                        placeholder="Senior salary"
                        disabled={disable}
                    />
                </Grid>
            </Grid>
        </form>
    </Grid>
);
export default reduxForm({
    form: 'MessRoomsWorkers',
    validate: validateRequired(),
})(ReceptionWorkersForm);
