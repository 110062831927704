import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Drawer,
    makeStyles,
} from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { adminDashboardUrl, settings, adminListOfUsers, allGamesUrl } from '../../utils/routesPath';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        position: 'static',
        width: drawerWidth,
        zIndex: 1,
        backgroundColor: theme.palette.background.default
    },
    link: {
        fontSize: '.9rem',
        fontWeight: '500'
    },
    activeLink: {
        backgroundColor: theme.palette.primary.shade,
        "& *": {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        }
    }
}));
const navItems = [
    {
        title: 'Vytvořit hru',
        icon: <AddBoxIcon />,
        url: adminDashboardUrl
    },
    {
        title: 'Přehled her',
        icon: <SportsEsportsIcon />,
        url: adminDashboardUrl + allGamesUrl
    },
    {
        title: 'Přehled uživatelů',
        icon: <PeopleAltIcon />,
        url: adminDashboardUrl + adminListOfUsers
    },
    {
        title: 'Nastavení',
        icon: <SettingsIcon />,
        url: adminDashboardUrl + settings
    }
]
const AdminNavigation = () => {
    const classes = useStyles();
    const location = useLocation();
    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <div style={{ height: '.7rem' }} />
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={index} component={Link} to={item.url} className={classes.link + ' ' + (location.pathname === item.url && classes.activeLink)} button>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText disableTypography>{item.title}</ListItemText>
                    </ListItem>
                ))}
            </List>
        </Drawer>

    );
}

export default AdminNavigation;
