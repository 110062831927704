import { Grid, ButtonBase, Typography } from '@material-ui/core';
import React from 'react';

const Tab = ({
    children, value, id, tabChange,
}) => {
    const activeClass = value === id ? 'Tab--active' : '';
    return (
        <Grid container onClick={(e) => tabChange(id)}>
            <ButtonBase style={{ marginTop: '2px' }}>
                <div style={{ width: '55px' }} className={activeClass}>
                    <Grid
                        item
                        className="Tab MuiGrid-align-items-xs-center"
                        style={{ justifyContent: 'center' }}
                    >
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            className="txt-al--cen Label"
                        >
                            {children}
                        </Typography>
                    </Grid>
                </div>
            </ButtonBase>
        </Grid>
    );
};

export default Tab;
